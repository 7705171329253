import * as React from 'react';

import { UserProvider } from '../../context/UserProfile';

import CmiRedeem from './CmiRedeem';
import RedeemBackground from './RedeemBackground';
import CopyrightFooter from '../copyright_footer';
import Gallery from '../gallery/Gallery';

const CmiRedeemMain = () => {

    const [campaignId, setCampaignId] = React.useState(null); // 'd836e5113c9046ffba03cb549e8cfd4d';

    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            background: '#fff',
        }}>
            <UserProvider>
                <RedeemBackground />
                <CmiRedeem onCampaignIdChange={setCampaignId} />
                <Gallery campaignId={campaignId} />
                <CopyrightFooter />
            </UserProvider >
        </div>
    )
}

export default CmiRedeemMain;
