
import * as React from 'react';
import { DateTime } from 'luxon';

// MUI
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

import firebase from 'firebase/compat/app';
import theme from '../../theme';
import useStyles from './styles';

import FirebaseSignIn from '../signin/FirebaseSignIn';
import { getCampaignSummary } from '../../controllers/amuro_services';
import amuroLogo from '../images/Amuro_logo_wip_banner_cropped.png';

export default function CampaignSummary() {
    const [firebaseUser, setFirebaseUser] = React.useState(null);

    // data
    const [campaignSummary, setCampaignSummary] = React.useState(null);
    const [selectedCamapaign, setSelectedCamapaign] = React.useState('');

    const themedStyles = useStyles();

    const logo_styles = {
        display: "block",
        margin: "auto",
        width: "50%",
        textColor: "#3266C2",
    }

    const logOut = async () => {
        if (firebaseUser) {
            await firebase.auth().signOut();
        }
        setCampaignSummary(null);
        setSelectedCamapaign('');
        console.log("logged out");
    }

    React.useEffect(() => {
        const authStateListener = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
            if (firebaseUser) {
                let result = await getCampaignSummary(await firebaseUser.getIdToken());
                if (result && Object.keys(result) > 0) {
                    setSelectedCamapaign(Object.keys(result)[0]);
                }
                setCampaignSummary(result);
            }
        });
        return () => {
            authStateListener();
        }
    }, []);

    const handleOpenseaUrlClick = (event, cellValues) => {
        let url = cellValues.row.opensea_url;
        console.log(cellValues);
        if (url) {
            window.open(url);
        }
    }

    const handleSelectCampaign = (event) => {
        setSelectedCamapaign(event.target.value);
    };

    const getColumns = React.useCallback(() => {
        if (campaignSummary) {
            return [
                { field: 'redemption_code', headerName: 'Redemption Code', width: 300 },
                { field: 'is_redeemded', headerName: 'Redeemed', width: 80 },
                { field: 'chain_id', headerName: 'Chain ID', width: 70 },
                {
                    field: 'contract_address', headerName: 'Contract Address', width: 200,
                    renderCell: (cellValues) => {
                        return (
                            <Tooltip
                                title={cellValues.row.contract_address}
                                enterTouchDelay={0}
                            >
                                <Typography className={themedStyles.contractAddressTooltipText}>
                                    {cellValues.row.contract_address}
                                </Typography>
                            </Tooltip>
                        )
                    }
                },
                { field: 'token_id', headerName: 'Token ID', width: 70 },
                { field: 'redemption_date', headerName: 'Redemption Date', width: 150 },
                {
                    field: 'opensea_url', headerName: 'Open In Opensea', width: 120,
                    renderCell: (cellValues) => {
                        return (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(event) => {
                                    handleOpenseaUrlClick(event, cellValues);
                                }}
                            >
                                View
                            </Button>
                        );
                    }
                },
            ]
        } else {
            return [];
        }
    }, [campaignSummary])

    const getRows = React.useCallback(() => {
        if (campaignSummary && selectedCamapaign) {
            let values = campaignSummary[selectedCamapaign];
            let results = [];
            values.forEach(each => {
                let clone = structuredClone(each);
                if (clone.redemption_date) {
                    clone.redemption_date = DateTime.fromISO(clone.redemption_date).toFormat('dd-LLL-yyyy');
                }
                results.push(clone);
            });
            return results;
        } else {
            return [];
        }
    }, [campaignSummary, selectedCamapaign])

    const getCampaignNames = React.useCallback(() => {
        if (campaignSummary) {
            let keys = Object.keys(campaignSummary);
            return keys;
        } else {
            return [];
        }
    }, [campaignSummary])

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        alignItems: 'center',
                        justifyContent: "center"
                    }}
                >
                    <img src={amuroLogo} alt="Logo" style={logo_styles} />
                    {!firebaseUser && <Typography component="h1" variant="h5">Sign-in / Register as corporate user</Typography>}
                    {!firebaseUser && <FirebaseSignIn />}

                    {firebaseUser && <Typography component="h1" variant="h5">Corporate user signed-in as {firebaseUser.displayName}</Typography>}
                    {firebaseUser && <Button variant='contained' onClick={logOut} sx={{ "my": "10px" }}>Sign out</Button>}
                    {campaignSummary ?
                        <>
                            <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <InputLabel id="demo-simple-select-autowidth-label">Campaign</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    value={selectedCamapaign}
                                    onChange={handleSelectCampaign}
                                    autoWidth
                                    label="Campaign"
                                >
                                    {
                                        getCampaignNames().map((each, index) => {
                                            return <MenuItem key={index} value={each}>{each}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Box sx={{ height: 600, width: '100%' }} >
                                <DataGrid
                                    rows={getRows()}
                                    columns={getColumns()}
                                    getRowId={(row) => row.token_id}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                />
                            </Box>
                        </>
                        :
                        null
                    }
                </Box>
            </Container>
        </ThemeProvider>
    );
}
