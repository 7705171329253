import * as React from 'react';
import useStyles from './styles';
import { Typography, Link } from '@mui/material';

const CopyrightFooter = () => {
    const themedStyles = useStyles();

    return (
        <Typography className={themedStyles.container}>
            {`Copyright © `}
            <Link color="inherit" href="https://amuro.io">
                Amuro
            </Link>
            {` ${new Date().getFullYear()}.`}
        </Typography>
    );
}

export default CopyrightFooter;
