import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
    },
    redEllipse: {
        position: 'absolute',
        background: `rgba(194, 42, 55, 0.3)`,
        top: '10rem',
        right: '20%',
        [theme.breakpoints.down('md')]: {
            top: '20%',
            right: '10%',
        },
        width: '10rem',
        height: '10rem',
        filter: `blur(5rem)`,
    },
    blueEllipse: {
        position: 'absolute',
        background: `#3266C2`,
        bottom: '10rem',
        left: '5%',
        width: '10rem',
        height: '10rem',
        filter: `blur(6rem)`,
    },
    upwardVector: {
        display: 'relative',
        position: 'absolute',
        top: '0%',
        right: '0%',
        color: `rgba(194, 42, 55, 0.5)`,
        filter: `blur(3px)`,
    },
    downwardVector: {
        display: 'relative',
        position: 'absolute',
        left: '0%',
        bottom: '0%',
        color: `rgba(194, 42, 55, 0.5)`,
        filter: `blur(3px)`,
    },
}));

export default useStyles;
