import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    contentContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
    },
    requestAccessButton: {
        marginTop: '10px',
        marginBottom: '10px'
    }
}));

export default useStyles;
