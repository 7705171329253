import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        alignSelf: 'center',
        color: theme.palette.text.grey,
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.75rem !important',
        fontWeight: '400 !important',
        textAlign: 'center',
        padding: '1rem 0',
    },
}));

export default useStyles;
