import * as React from 'react';
import { Button, Typography, Snackbar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';

const SelfCustomizeUITooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.popper}`]: {

    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F5F5F5',
        color: '#808080',
        padding: '0.5rem',
        marginTop: '0.7rem !important',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#F5F5F5',
    },
});

const CustomToolTip = ({ tooltipText, url, children }) => {
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false);
    };

    const openUrl = () => {
        if (url) {
            window.open(url);
        }
    }

    return (
        <>
            <SelfCustomizeUITooltip
                enterTouchDelay={0}
                placement={'bottom'}
                arrow
                title={
                    (tooltipText) ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', overflow: 'hidden' }}>
                            <Typography sx={{ fontFamily: 'Noto Sans TC', fontWeight: '400', fontSize: '0.87rem', lineHeight: '1.25rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                {tooltipText}
                            </Typography>
                            <Button
                                sx={{ minWidth: 0, padding: '5px 0' }}
                                onClick={async () => {
                                    await navigator.clipboard.writeText(tooltipText);
                                    setSnackbarOpen(true)
                                }}
                            >
                                <ContentCopyIcon sx={{ marginLeft: '0.5rem', color: '#808080' }} />
                            </Button>
                            {
                                (url) ?
                                <Button
                                    sx={{ minWidth: 0, padding: '5px 0' }}
                                    onClick={async () => {
                                        openUrl();
                                    }}
                                >
                                    <OpenInNewIcon sx={{ marginLeft: '0.5rem', color: '#808080' }} />
                                </Button>
                                :
                                null
                            }

                        </div> : ''
                }
            >
                {children}
            </SelfCustomizeUITooltip>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message="Copied"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>
    );
}

export default CustomToolTip;
