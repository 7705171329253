import * as React from 'react';
import { useParams } from 'react-router-dom';

// MUI
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Tooltip, useMediaQuery, useTheme, } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles'
import detectEthereumProvider from '@metamask/detect-provider'
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

// UI
import useStyles from './styles';
import amuroLogo from '../images/Amuro_logo_wip_banner_cropped.png';
import CustomToolTip from './CustomTooltip';

// Others
import { QrReader } from 'react-qr-reader';
import QRCode from "react-qr-code";

// Firebase
import FirebaseSignIn from '../signin/FirebaseSignIn';
import firebase from 'firebase/compat/app';

// Project
import { getCustodianWalletAddress, getWalletQrCode, getLoyaltyPointHistory } from '../../controllers/amuro_services';
import { getNetworkInfo } from '../../utils/blockchain_utils';
import { requestConnectMetamask } from '../../utils/metamask_utils';
import { UserContext, UserDispatchContext } from '../../context/UserProfile';
import * as MetamaskUtils from '../../utils/metamask_utils';
import { requestSignMessage, getMetamaskInstallStatus } from '../../utils/metamask_utils';

export default function Loyalty() {

    // Context
    const userDetails = React.useContext(UserContext);
    const setUserDetails = React.useContext(UserDispatchContext);

    const { ethereum } = window;

    const [wallet, setWallet] = React.useState(null);
    const [signedMessageCredentials, setSignedMessageCredentials] = React.useState(null);
    const [isFetchingCustodianWalletInfo, setIsFetchingCustodianWalletInfo] = React.useState(false);
    const [networkInfo, setNetworkInfo] = React.useState({ name: null, chain_id: null });
    const [metamaskInstallStatus, setMetamaskInstallStatus] = React.useState(MetamaskUtils.METAMASK_STATUS_UNKNOWN);

    const [userQrCode, setUserQrCode] = React.useState(null);
    const [loyaltyHistory, setLoyaltyHistory] = React.useState(null);
    const [showFirebaseSignIn, setShowFirebaseSignIn] = React.useState(true);
    const [firebaseUser, setFirebaseUser] = React.useState(null);
    const [metamaskButtonText, setMetamaskButtonText] = React.useState('Detecting Metamask...');

    const themedStyles = useStyles();
    const theme = useTheme();
    const isMobileForUI = useMediaQuery(theme.breakpoints.down('md'));

    const openLink = (url) => {
        window.open(url);
    }

    const connectMetamask = React.useCallback(async (event) => {
        event.preventDefault();
        console.log(`Trying to connect wallet`);
        switch (metamaskInstallStatus) {
            case MetamaskUtils.METAMASK_STATUS_INSTALLED_WEB:
            case MetamaskUtils.METAMASK_STATUS_INSTALLED_MOBILE:
                let result = await requestConnectMetamask(ethereum, networkInfo);
                if (result) {
                    let encodedWalletCredentials = await requestSignMessage(window.ethereum);
                    if (encodedWalletCredentials) {
                        setWallet(result);
                        setUserDetails({
                            wallet: result
                        });
                    }
                    setSignedMessageCredentials(encodedWalletCredentials);
                }
                break;
            case MetamaskUtils.METAMASK_STATUS_REDIRECT_TO_METAMASK_APP:
                // Try to trigger Metamask app
                console.log(`Trying to trigger Metamask app`);
                let url = `https://metamask.app.link/dapp/${process.env.REACT_APP_METAMASK_DAPP_HOST}/redeem`;
                if (redemptionCode && redemptionCode.length > 0) {
                    url = `${url}/${redemptionCode}`;
                }
                openLink(url);
                break;
            case MetamaskUtils.METAMASK_STATUS_ASK_TO_INSTALL_METAMASK_WEB_EXTENSION:
                let metamaskWebsiteUrl = `https://metamask.io/download/`;
                openLink(metamaskWebsiteUrl);
                break;
            default:
                console.log(`connectMetamask, Unknown Metamask install status`);
                break;
        }
    }, [metamaskInstallStatus, networkInfo]);

    const logOut = async () => {
        setWallet(null);
        setUserDetails({
            wallet: null
        })
        setSignedMessageCredentials(null);
        if (firebaseUser) {
            await firebase.auth().signOut();
        }
        console.log("logged out");
    }

    React.useEffect(() => {
        console.log(`installStatus: ${metamaskInstallStatus}`);
        switch (metamaskInstallStatus) {
            case MetamaskUtils.METAMASK_STATUS_UNKNOWN:
                setMetamaskButtonText(`Detecting Metamask...`);
                break;
            case MetamaskUtils.METAMASK_STATUS_INSTALLED_WEB:
                setMetamaskButtonText(`Connect to Metamask`);
                break;
            case MetamaskUtils.METAMASK_STATUS_INSTALLED_MOBILE:
                // Do not allow Firebase sign-in within Metamask app,
                // it does not support at the moment
                setShowFirebaseSignIn(false);
                setMetamaskButtonText(`Metamask wallet`);
                break;
            case MetamaskUtils.METAMASK_STATUS_REDIRECT_TO_METAMASK_APP:
                setMetamaskButtonText(`Open Metamask app`);
                break;
            case MetamaskUtils.METAMASK_STATUS_ASK_TO_INSTALL_METAMASK_WEB_EXTENSION:
                setMetamaskButtonText(`Install Metamask extension`);
                break;
            default:
                console.log(`Unknown Metamask install status`);
                break;
        }
    }, [metamaskInstallStatus])

    React.useEffect(() => {
        async function getUserQrCode() {
            let firebaseUid = firebaseUser ? await firebaseUser.getIdToken() : null;
            if (firebaseUid || signedMessageCredentials) {
                let result = await getWalletQrCode(firebaseUid, signedMessageCredentials);
                if (result && result.qr_string) {
                    setUserQrCode(result.qr_string);
                }
            }
        }
        async function getLoyaltyHistory() {
            let firebaseUid = firebaseUser ? await firebaseUser.getIdToken() : null;
            if (firebaseUid || signedMessageCredentials) {
                let history = await getLoyaltyPointHistory(firebaseUid, signedMessageCredentials);
                if (history) {
                    setLoyaltyHistory(history);
                }
            }
        }
        if (wallet) {
            getUserQrCode();
            getLoyaltyHistory();
        } else {
            setUserQrCode(null);
            setLoyaltyHistory(null);
        }
    }, [wallet])

    React.useEffect(() => {
        async function detectMetamask() {
            let metamaskStatus = await getMetamaskInstallStatus(window);
            console.log(`metamaskStatus: ${metamaskStatus}`);
            setMetamaskInstallStatus(metamaskStatus);
        };
        setNetworkInfo(getNetworkInfo());
        detectMetamask();
        const authStateListener = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
            if (firebaseUser) {
                setIsFetchingCustodianWalletInfo(true);
                let result = await getCustodianWalletAddress(await firebaseUser.getIdToken());
                setIsFetchingCustodianWalletInfo(false);
                if (result && result.address) {
                    setWallet(result.address);
                    setUserDetails({
                        wallet: result.address
                    })
                } else {
                    console.log(`No custodian wallet address returned from server`);
                }
            }
        });

        return () => {
            authStateListener();
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container className={themedStyles.container}>
                <CssBaseline />
                <Box className={themedStyles.mainContainer}>
                    <Typography className={themedStyles.developingTitleText}>
                        {process.env.REACT_APP_ENV == 'prod' ? 'Renovation in progress, stay tuned! We will come back with a new look :)' : 'WARNING! THIS IS TESTING ENVIRONMENT!'}
                    </Typography>
                    <img src={amuroLogo} alt="Logo" className={themedStyles.titleImage} />
                    <Box
                        noValidate
                        className={themedStyles.contentBoxContainer}
                    >
                        {
                            /*
                                Sign-in section
                            */
                            (!wallet) ?
                                <div className={themedStyles.signInButtonContainer}>
                                    <Typography className={themedStyles.titleText}>
                                        {'Sign in with:'}
                                    </Typography>
                                    {
                                        (isFetchingCustodianWalletInfo) ?
                                            <Typography className={themedStyles.fetchingCustodianWalletText}>
                                                {'Retrieving your wallet info, please wait...'}
                                            </Typography>
                                            :
                                            <>
                                                <Button
                                                    onClick={connectMetamask}
                                                    variant="contained"
                                                    className={themedStyles.metaMaskButton}
                                                >
                                                    <Typography className={themedStyles.metaMaskText}>{metamaskButtonText}</Typography>
                                                </Button>
                                                <div className={themedStyles.metaMaskAdditionInfoContainer}>
                                                    <Typography className={themedStyles.metaMaskMemoText}>
                                                        {'Please note: Only Metamask provides a non-custodian wallet'}
                                                    </Typography>
                                                    <div className={themedStyles.signinHelperContainer}>
                                                        <Tooltip
                                                            title={`Only Metamask provides a non-custodian wallet`}
                                                            enterTouchDelay={0}
                                                            arrow
                                                            className={themedStyles.enquiryTooltip}
                                                        >
                                                            <Typography className={themedStyles.learnMoreText}>
                                                                {'Learn More'}
                                                            </Typography>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={`Only Metamask provides a non-custodian wallet`}
                                                            enterTouchDelay={0}
                                                            arrow
                                                            className={themedStyles.enquiryTooltip}
                                                        >
                                                            <HelpOutlineIcon sx={{ color: '#214482' }} />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                {
                                                    (showFirebaseSignIn) &&
                                                    <>
                                                        <div className={themedStyles.signInSeperatorContainer}>
                                                            <div className={themedStyles.seperatorStroke} />
                                                            <Typography className={themedStyles.signInSeperatorText}>
                                                                {'Or'}
                                                            </Typography>
                                                            <div className={themedStyles.seperatorStroke} />
                                                        </div>
                                                        <FirebaseSignIn />
                                                    </>
                                                }
                                            </>
                                    }
                                </div> : null
                        }
                        {
                            /**
                             * Signed-in section
                             */
                            (wallet) ?
                                <div className={themedStyles.submitContainer}>
                                    {firebaseUser ?
                                        /**
                                         * Firebase users - user display name
                                         */
                                        <Typography className={themedStyles.userConnectedStatusText}>
                                            {
                                                (isMobileForUI) ?
                                                    `User connected as ${firebaseUser.displayName}\n(Custodian wallet)` :
                                                    `User connected as ${firebaseUser.displayName} (Custodian wallet)`
                                            }
                                        </Typography>
                                        :
                                        null
                                    }
                                    {/**
                                         * Metamask users - native wallet info
                                         */
                                        <CustomToolTip tooltipText={wallet}>
                                            <div className={themedStyles.walletStatusContainer}>
                                                <div className={themedStyles.walletStatusTextContainer}>
                                                    {
                                                        (wallet) ?
                                                            <Typography className={themedStyles.walletStatusText}>
                                                                {
                                                                    (wallet.length > 8) ?
                                                                        `Connected to ${wallet.slice(0, 6)}...${wallet.slice(-4)}` :
                                                                        `Connected to ${wallet}`
                                                                }
                                                            </Typography> :
                                                            <Typography className={themedStyles.walletStatusText}>
                                                                {'Connecting...'}
                                                            </Typography>
                                                    }
                                                </div>

                                                <div className={themedStyles.walletStatusSeperator} />
                                                <Typography
                                                    className={themedStyles.disconnectText}
                                                    onClick={logOut}
                                                >
                                                    {`disconnect`}
                                                </Typography>
                                            </div>
                                        </CustomToolTip>
                                    }
                                    {
                                        (userQrCode) ?
                                            <>
                                                <div className={themedStyles.qrCodeSeperatorContainer}>
                                                    <div className={themedStyles.seperatorStroke} />
                                                    <Typography className={themedStyles.qrCodeTitle}>
                                                        {'Your member QR code:'}
                                                    </Typography>
                                                    <div className={themedStyles.seperatorStroke} />
                                                </div>
                                                <QRCode value={userQrCode} />
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        (loyaltyHistory && loyaltyHistory.length > 0) ?
                                            <Typography className={themedStyles.loyaltyPointText}>
                                                {`${loyaltyHistory[0].name}: ${loyaltyHistory[0].balance} points`}
                                            </Typography>
                                            :
                                            null
                                    }
                                </div> : null
                        }
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    );
}
