import * as React from 'react';

import { UserProvider } from '../../context/UserProfile';

import CopyrightFooter from '../copyright_footer';

import Loyalty from './Loyalty';

const LoyaltyMain = () => {
    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            background: '#fff',
        }}>
            <UserProvider>
                <Loyalty />
                <CopyrightFooter />
            </UserProvider >
        </div>
    )
}

export default LoyaltyMain;
