import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        alignSelf: 'center',
        justifyContent: 'center',
        width: '100%',
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.darkGreen,
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.75rem !important',
        fontWeight: '400 !important',
        textAlign: 'center',
        padding: '2rem 0',
    },
}));

export default useStyles;
