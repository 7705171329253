import axios from "axios";

export async function get(path, queryParams, options, throwError = false) {
    try {
        let params;
        if (queryParams) {
            params = new URLSearchParams(queryParams);
        } else {
            params = new URLSearchParams({});
        }
        let response = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${path}?${params.toString()}`, options);
        let responseData = response.data;
        if (!responseData) {
            console.log(`No data found in response: ${JSON.stringify(response)}`);
            return null;
        }
        return responseData;
    } catch (err) {
        if (throwError) {
            throw err;
    }
    }
    return null;
}

export async function post(path, body, options, throwError = false) {
    try {
        let response = await axios.post(`${process.env.REACT_APP_SERVER_HOST}${path}`, body, options);
        let responseData = response.data;
        if (!responseData) {
            console.log(`No data found in response: ${JSON.stringify(response)}`);
            return null;
        }
        return responseData;
    } catch (err) {
        if (throwError) {
            throw err;
    }
    }
    return null;
}
