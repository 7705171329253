import * as AmuroNetworkController from './amuro_network_controller';

let insertCredentialsIntoHeaders = (headers, firebaseJwt, walletCredentials) => {
    if (firebaseJwt) {
        headers['Authorization'] = 'Bearer ' + firebaseJwt;
    }
    if (walletCredentials) {
        headers['wallet-credentials'] = walletCredentials;
    }
    return headers
}

export async function redeem(firebaseJwt, walletCredentials, redemptionCode, address) {
    console.log(`redeem triggered`);
    return AmuroNetworkController.post(`/campaigns/redeem`, {
        redemption_code: redemptionCode,
        recipient_address: address
    }, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, walletCredentials)
    }, /*throwError*/true);
}

export async function getAvailableTestRedemptionCodes(contractAddress, campaignId) {
    console.log(`campaignId: ${campaignId}`);
    let body = {};
    if (campaignId) {
        body.campaign_id = campaignId;
    }
    if (contractAddress) {
        body.contract_address = contractAddress;
    }
    return AmuroNetworkController.get(`/redemptions/get_all_available_test_redemptions`, body);
}

export async function findWallet(firebaseJwt, password) {
    console.log(`findWallets triggered`);
    return AmuroNetworkController.post(`/wallet/get_custodian_wallet`, {
        password: password
    }, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, /*walletCredentials*/null)
    });
}

export async function isWalletExist(firebaseJwt) {
    console.log(`isWalletExist triggered`);
    return AmuroNetworkController.post(`/wallet/is_wallet_exist`, {}, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, /*walletCredentials*/null)
    });
}

export async function createWallet(firebaseJwt, passwords) {
    console.log(`createWallet triggered`);
    return AmuroNetworkController.post(`/wallet/create_custodian_wallet`, {
        passwords: passwords
    },
        {
            headers: insertCredentialsIntoHeaders({}, firebaseJwt, /*walletCredentials*/null)
        });
}

export async function subscribeStayTuned(email) {
    return AmuroNetworkController.post(`/wallet/create_custodian_wallet`, {
        passwords: passwords
    },
        {
            headers: insertCredentialsIntoHeaders({}, firebaseJwt, /*walletCredentials*/null)
        });
}

export async function getCustodianWalletAddress(firebaseJwt) {
    console.log(`getCustodianWalletAddress triggered`);
    return AmuroNetworkController.post(`/wallet/firebase_sign_in_custodian_wallet`, {}, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, /*walletCredentials*/null)
    });
}

export async function developerLogin(firebaseJwt) {
    console.log(`developerLogin triggered`);
    return AmuroNetworkController.post(`/developer/login`, {}, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, /*walletCredentials*/null)
    });
}

export async function getAllOwnedTokens(firebaseJwt, walletCredentials) {
    console.log(`getAllOwnedTokens triggered`);
    return AmuroNetworkController.post(`/web/token/retrieve_all_amuro_tokens`, {}, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, walletCredentials)
    });
}

export async function getCampaignTokens(campaignIds, firebaseJwt, walletCredentials) {
    console.log(`getCampaignTokens triggered`);
    return AmuroNetworkController.post(`/web/token/retrieve_campaign_tokens`, {
        campaign_ids: campaignIds
    }, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, walletCredentials)
    });
}

export async function getCampaignId(campaignName, firebaseJwt, walletCredentials) {
    console.log(`getCampaignId triggered`);
    return AmuroNetworkController.post(`/web/campaign/retrieve_campaign_id`, {
        campaign_name: campaignName
    }, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, walletCredentials)
    });
}

export async function requestAccessByJwt(firebaseJwt, accessCode) {
    console.log(`requestAccessByJwt triggered`);
    return AmuroNetworkController.post(`/web/access/request_access`, { access_code: accessCode }, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, /*walletCredentials*/null)
    });
}

export async function requestAccessByNativeWallet(wallet, message, signature, accessCode) {
    console.log(`requestAccessByJwt triggered`);
    return AmuroNetworkController.post(`/web/access/request_access`,
        { 
            access_code: accessCode,
            address: wallet,
            message: message,
            signature: signature
        }
    );
}

export async function getSigningMessage() {
    console.log(`getSigningMessage triggered`);
    return AmuroNetworkController.get(`/wallet/get_signing_message`, {});
}

export async function getRedemptionRequests(firebaseJwt, walletCredentials) {
    console.log(`getRedemptionRequests triggered`);
    return AmuroNetworkController.post(`/web/token/retrieve_redemption_requests`, {}, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, walletCredentials)
    });
}

export async function getWalletQrCode(firebaseJwt, walletCredentials) {
    console.log(`getWalletQrCode triggered`);
    return AmuroNetworkController.post(`/web/user/retrieve_qr_string`, {}, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, walletCredentials)
    });
}

export async function getCampaignSummary(firebaseJwt) {
    console.log(`getCampaignSummary`);
    return AmuroNetworkController.get(`/corporate/campaign/get_redemption_summary`, {}, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, /*walletCredentials*/null)
    });
}

export async function getLoyaltyPointHistory(firebaseJwt, walletCredentials) {
    console.log(`getLoyaltyPointHistory`);
    return AmuroNetworkController.post(`/web/loyalty/retrieve_loyalty_point_history`, {}, {
        headers: insertCredentialsIntoHeaders({}, firebaseJwt, walletCredentials)
    });
}
