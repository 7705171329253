import * as React from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles'

import theme from '../../theme';

import { getAvailableTestRedemptionCodes } from '../../controllers/amuro_services';

export default function TestRedemptionCodes(props) {

    const [codes, setCodes] = React.useState(null);

    React.useEffect(() => {
        async function test() {
            let codes = await getAvailableTestRedemptionCodes(props.contractAddress, props.campaignId);
            setCodes(codes);
        };
        test();
    }, []);

    if (process.env.REACT_APP_ENV == 'prod') {
        return (<ThemeProvider theme={theme}>
            <Typography variant="h4">
                Not supported.
            </Typography>
        </ThemeProvider>)
    } else {
        return (
            <ThemeProvider theme={theme}>
                <Typography variant="h4">
                    Amuro test redemption codes:
                </Typography>
                <Typography variant="h5">
                    {`Contract address: ${props.contractAddress ? props.contractAddress : '0x423c8F7c90040B074b92cb8A9bE24843597B9c6f'}`}
                </Typography>
                <Typography variant="h5">
                    {`Network: Mumbai (80001)`}
                </Typography>
                <Typography variant="body1">
                    {codes != null ? codes : ''}
                </Typography>
            </ThemeProvider>
        );
    }
}
