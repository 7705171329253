
import * as React from 'react';

// MUI
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

// styles
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme';
import useStyles from './styles';

// Others
import firebase from 'firebase/compat/app';
import { QrReader } from 'react-qr-reader';

// first party
import { getAllOwnedTokens, getCampaignTokens } from '../../controllers/amuro_services';
import { isSameAddress } from '../../utils/blockchain_utils';
import { requestSignMessage } from '../../utils/metamask_utils';

// Context
import { UserContext } from '../../context/UserProfile';

export default function Gallery(props) {

    // Context
    const userDetails = React.useContext(UserContext);

    const [firebaseUser, setFirebaseUser] = React.useState(null);
    const themedStyles = useStyles();

    // data
    const [tokens, setTokens] = React.useState(null);
    const [selectedShowInfoToken, setSelectedShowInfoToken] = React.useState(null);

    // UI
    const [triggeredShowTokens, setTriggeredShowTokens] = React.useState(false);
    const [enableShowTokenButton, setEnableShowTokenButton] = React.useState(false);
    const [isRetrievingTokens, setIsRetrievingTokens] = React.useState(false);
    const [tokenInfoDialogOpen, setTokenInfoDialogOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarText, setSnackBarText] = React.useState('');

    const theme = useTheme();
    const isMobileForUI = useMediaQuery(theme.breakpoints.down('md'));

    const retrieveAllOwnedToken = async () => {
        let firebaseJwt = firebaseUser ? await firebaseUser.getIdToken() : null;
        let walletCredentials = userDetails.walletCredentials;
        if (firebaseJwt || walletCredentials) {
            setIsRetrievingTokens(true);
            let raw;
            console.log(`props:`);
            console.log(props);
            if (props.campaignId) {
                raw = await getCampaignTokens([props.campaignId], firebaseJwt, walletCredentials);
            } else {
                raw = await getAllOwnedTokens(firebaseJwt, walletCredentials);
            }
            let results = [];
            console.log(raw);
            if (raw) {
                for (let i = 0; i < raw.length; i++) {
                    let each = raw[i];
                    let metadata = JSON.parse(each.metadata);
                    let body = {
                        title: each.name,
                        subtitle: `${each.symbol} #${each.token_id}`,
                        ui_key: each.token_hash,
                        raw: each
                    };
                    if (metadata) {
                        if (metadata.image) {
                            let imageIpfsCid = metadata.image.replace('ipfs://', '');
                            let ipfsGateway = each.token_uri.split('/ipfs/')[0];
                            let imageUrl = `${ipfsGateway}/ipfs/${imageIpfsCid}`;
                            body.img = imageUrl;
                        }
                        if (metadata.animation_url) {
                            let imageIpfsCid = metadata.animation_url.replace('ipfs://', '');
                            let ipfsGateway = each.token_uri.split('/ipfs/')[0];
                            let videoUrl = `${ipfsGateway}/ipfs/${imageIpfsCid}`;
                            console.log(`videoUrl:${videoUrl}`);
                            body.videoUrl = videoUrl;
                        }
                        if (metadata.name) {
                            body.title = metadata.name
                        }
                    } else {
                        console.log(`No metadata:`);
                        console.log(each);
                    }
                    results.push(body);
                }
                setTokens(results);
            }
            setIsRetrievingTokens(false);
        } else {
            console.log(`Neither firebaseJwt nor walletCredentials found`);
        }
    }

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
        });
    }, []);

    React.useEffect(() => {
        setEnableShowTokenButton(userDetails.wallet);
        if (!userDetails.wallet) {
            setTokens(null);
            setTriggeredShowTokens(false);
        }
    }, [userDetails.wallet]);

    const onShowTokenClick = () => {
        setTriggeredShowTokens(true);
        retrieveAllOwnedToken();
    }

    const handleTokenInfoCloseDialog = (event, reason) => {
        // Do not close the dialog when it is backdrop click
        if (reason !== 'backdropClick') {
            setTokenInfoDialogOpen(false);
        }
    };

    const onTokenInfoClick = (item) => {
        if (item) {
            setSelectedShowInfoToken(item);
            setTokenInfoDialogOpen(true);
        }
    }

    const onTokenDownloadClick = React.useCallback(() => {
        console.log(`onTokenDownloadClick: ${selectedShowInfoToken}`);
        if (selectedShowInfoToken.videoUrl) {
            window.open(selectedShowInfoToken.videoUrl);
        } else if (selectedShowInfoToken.img) {
            window.open(selectedShowInfoToken.img);
        } else {
            console.log(`No url to open`);
        }
    }, [selectedShowInfoToken])

    React.useEffect(() => {
        if (snackbarText) {
            setSnackbarOpen(true);
        }
    }, [snackbarText]);

    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false);
        setSnackBarText('');
    };

    const openOpenseaUrl = React.useCallback(() => {
        if (selectedShowInfoToken && selectedShowInfoToken.raw) {
            let tokenRaw = selectedShowInfoToken.raw;
            let url = null;
            switch (tokenRaw.chain_id) {
                case "80001":
                    url = `https://testnets.opensea.io/assets/mumbai/${tokenRaw.token_address}/${tokenRaw.token_id}`;
                    break;
                case "137":
                    url = `https://opensea.io/assets/matic/${tokenRaw.token_address}/${tokenRaw.token_id}`;
                    break;
                default:
                    console.log(`Unknown chain_id of token: ${tokenRaw.chain_id}`);
                    break;
            }
            if (url) {
                window.open(url);
            }
        }
        console.log(`Need to implement openOpenseaUrl`);
    }, [selectedShowInfoToken])

    const getMediaComponent = (item) => {
        if (item.videoUrl) {
            return (
                <video
                    width="240"
                    height="426"
                    controls
                    type="video/mp4"
                    src={`${item.videoUrl}`}
                />
            );
        } else if (item.img) {
            return (
                <img
                    src={`${item.img}`}
                    srcSet={`${item.img}`}
                    alt={item.title}
                    loading="lazy"
                />
            )
        } else {
            return null;
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container className={themedStyles.container}>
                <CssBaseline />
                <Box className={themedStyles.mainContainer}>
                    {
                        (triggeredShowTokens) ?
                            <>
                                {
                                    isRetrievingTokens ?
                                        <Typography className={themedStyles.amuroTokenTitleText}>
                                            {`Retrieving your tokens from server...`}
                                        </Typography>
                                        :
                                        null
                                }
                                {
                                    (tokens == null) ?
                                        null
                                        :
                                        (tokens.length > 0) ?
                                            <>
                                                <Typography className={themedStyles.amuroTokenTitleText}>
                                                    {`Your Amuro token(${tokens ? tokens.length : 0}) :`}
                                                </Typography>
                                                <ImageList
                                                    sx={{ maxWidth: 800, width: '90%' }}
                                                    cols={(isMobileForUI) ? 1 : 3}
                                                >
                                                    {tokens.map((item) => (
                                                        <ImageListItem key={item.ui_key}>
                                                            {getMediaComponent(item)}
                                                            <ImageListItemBar
                                                                title={item.title}
                                                                subtitle={<span>{item.subtitle}</span>}
                                                                position="below"
                                                            />
                                                            <Button
                                                                onClick={() => { onTokenInfoClick(item) }}
                                                                variant="contained"
                                                                className={themedStyles.downloadButton} >
                                                                Info
                                                            </Button>
                                                        </ImageListItem>
                                                    ))}
                                                </ImageList>
                                            </>
                                            :
                                            <Typography className={themedStyles.amuroTokenTitleText}>
                                                You have no Amuro tokens
                                            </Typography>
                                }
                                {
                                    (selectedShowInfoToken) ?
                                        <Dialog
                                            open={tokenInfoDialogOpen}
                                            onClose={handleTokenInfoCloseDialog}>
                                            <DialogTitle id="alert-dialog-title">
                                                {`Token info`}
                                            </DialogTitle>
                                            <DialogContent>
                                                <>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Contract address:
                                                    </DialogContentText>
                                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', overflow: 'hidden' }}>
                                                        <Typography sx={{ fontFamily: 'Noto Sans TC', fontWeight: '400', fontSize: '0.87rem', lineHeight: '1.25rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                            {selectedShowInfoToken.raw.token_address}
                                                        </Typography>
                                                        <Button
                                                            sx={{ minWidth: 0, padding: '5px 0' }}
                                                            onClick={async () => {
                                                                await navigator.clipboard.writeText(selectedShowInfoToken.raw.token_address);
                                                                setSnackBarText('Copied contract address');
                                                            }}
                                                        >
                                                            <ContentCopyIcon sx={{ marginLeft: '0.5rem', color: '#808080' }} />
                                                        </Button>
                                                    </div>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Token ID:
                                                    </DialogContentText>
                                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', overflow: 'hidden' }}>
                                                        <Typography sx={{ fontFamily: 'Noto Sans TC', fontWeight: '400', fontSize: '0.87rem', lineHeight: '1.25rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                            {selectedShowInfoToken.raw.token_id}
                                                        </Typography>
                                                    </div>
                                                    {
                                                        selectedShowInfoToken.raw.extra_info ?
                                                            selectedShowInfoToken.raw.extra_info.map(each => {
                                                                return (
                                                                    <Box
                                                                        key={each[1]}
                                                                    >
                                                                        <DialogContentText id="alert-dialog-description">
                                                                            {each[0]}
                                                                        </DialogContentText>
                                                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', overflow: 'hidden' }}>
                                                                            <Typography sx={{ fontFamily: 'Noto Sans TC', fontWeight: '400', fontSize: '0.87rem', lineHeight: '1.25rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                                                {each[1]}
                                                                            </Typography>
                                                                            <Button
                                                                                sx={{ minWidth: 0, padding: '5px 0' }}
                                                                                onClick={async () => {
                                                                                    await navigator.clipboard.writeText(each[1]);
                                                                                    setSnackBarText('Copied each[0]');
                                                                                }}
                                                                            >
                                                                                <ContentCopyIcon sx={{ marginLeft: '0.5rem', color: '#808080' }} />
                                                                            </Button>
                                                                        </div>
                                                                    </Box>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                </>
                                            </DialogContent>
                                            <DialogActions>
                                                {<Button onClick={onTokenDownloadClick}>Download Media</Button>}
                                                {<Button onClick={openOpenseaUrl}>View on Opensea</Button>}
                                                <Button onClick={handleTokenInfoCloseDialog}>OK</Button>
                                            </DialogActions>
                                        </Dialog>
                                        :
                                        null
                                }
                            </>
                            :
                            (userDetails.wallet) ?
                                <Button
                                    disabled={!enableShowTokenButton}
                                    type="Submit"
                                    variant="contained"
                                    className={themedStyles.showTokenButton}
                                    onClick={onShowTokenClick} >
                                    <Typography className={themedStyles.showTokenButtonText}>
                                        {"Show your Amuro tokens"}
                                    </Typography>
                                </Button>
                                :
                                null

                    }
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={2000}
                        onClose={handleSnackbarClose}
                        message={snackbarText}
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleSnackbarClose}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    />
                </Box>
            </Container>
        </ThemeProvider>
    );
}
