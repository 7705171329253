import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    contractAddressTooltipText: {
        textAlign: 'center',
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.7rem !important',
        fontWeight: '400 !important',
        letterSpacing: '0.4px !important',
        lineHeight: '1.25rem !important',
        margin: '0 0 0 0 !important',
    },
}));

export default useStyles;
