import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { createWallet } from '../../controllers/amuro_services';


const theme = createTheme();

const NUM_OF_PASSWORD_ALLOWED = 5;

export default function CreateWallet() {
    const [firebaseUser, setFirebaseUser] = React.useState(null);
    const [createdWallet, setCreatedWallet] = React.useState(null);
    const [passwordInput, setPasswordInput] = React.useState({});
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const getPasswordFieldPairs = () => {
        let pairs = {}
        for (let i=1; i<=NUM_OF_PASSWORD_ALLOWED; i++) {
            pairs[`password${i}`] = `password${i}_confirmation`;
        }
        return pairs
    }

    const submitCreateWallet = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setErrorMessage(null)
        const data = new FormData(event.currentTarget);
        let qualifiedPasswords = [];
        let pairs = getPasswordFieldPairs();
        let allPairsMatched = true;
        for (let [key1, key2] of Object.entries(pairs)) {
            let pwd = passwordInput[key1];
            let confirmation = passwordInput[key2];
            if (pwd != confirmation) {
                allPairsMatched = false;
                setErrorMessage(`${key1} does not match`);
                break;
            } else {
                qualifiedPasswords.push(pwd);
            }
        }
        if (allPairsMatched && (qualifiedPasswords.length > 0)) {
            try {
                let wallet = await createWallet(await firebaseUser.getIdToken(), qualifiedPasswords);
                setCreatedWallet(wallet);
            } catch (err) {
                console.log(err);
            }
        }
        setIsSubmitting(false);
    };

    const onFieldContentChange = (event) => {
        setPasswordInput({ ...passwordInput, [event.target.id]: event.target.value })
    }

    const enableSubmitButton = React.useMemo(() => {
        if (firebaseUser == null) {
            return false;
        }
        if (createdWallet) {
            return false;
        }
        if (isSubmitting) {
            return false;
        }
        let hasValues = false;
        for (let value of Object.values(passwordInput)) {
            if (value.length > 0) {
                hasValues = true;
                break;
            }
        }
        return hasValues;
    }, [firebaseUser, passwordInput, createdWallet, isSubmitting]);

    React.useEffect(() => {
        let currentUser = auth.currentUser;
        setFirebaseUser(currentUser);
        auth.onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
        })
    }, []);

    React.useEffect(() => {
        console.log(`Updating firebaseUser to ${JSON.stringify(firebaseUser)}`);
    }, [firebaseUser]);

    const getPasswordFields = () => {
        let grids = [];
        let passwordPairs = getPasswordFieldPairs();
        for (let [key1, key2] of Object.entries(passwordPairs)) {
            grids.push(
                <Grid item xs={12} sm={6}>
                    <TextField
                        key={key1}
                        name={key1}
                        required
                        fullWidth
                        id={key1}
                        label={key1}
                        onChange={onFieldContentChange}
                    />
                </Grid>
            )
            grids.push(
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        key={key2}
                        id={key2}
                        label="Confirmation"
                        name={key2}
                        onChange={onFieldContentChange}
                    />
                </Grid>
            )
        }
        return grids;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create Wallet
                    </Typography>
                    <Typography>
                        In order to create a wallet, you have to set at least 1 password (maximum 5 passwords).
                    </Typography> 
                    <Typography>
                    IMPORTANT!! All your wallet info will be encrypted in the server and they can ONLY be decrypted by one of your passwords. If you have forgotten all your passwords you will permanently lose your wallet info. We recommand you write down your passwords in a safe place. Once your wallet have been created, you cannot add/remove/change your passwords.
                    </Typography>
                    <Box component="form" noValidate onSubmit={submitCreateWallet} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            {!createdWallet && getPasswordFields()}
                        </Grid>
                        {
                            errorMessage &&
                            <Typography color="red">
                                {errorMessage}
                            </Typography>
                        }
                        <Button
                            disabled={!enableSubmitButton}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {isSubmitting ? <CircularProgress size={14} /> : "Create"}
                        </Button>
                        {
                            createdWallet &&
                            <Container>
                                <Typography variant="h5">{`Your wallet has been created as follow`}</Typography>
                                <Typography>{`Wallet address: "${createdWallet.address}"`}</Typography>
                                <Typography>{`private key: "${createdWallet.private_key}"`}</Typography>
                                <Typography>{`mnemonic: "${createdWallet.mnemonic}"`}</Typography>
                            </Container>
                        }
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}