import * as React from 'react';

// MUI
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme, } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles'
import detectEthereumProvider from '@metamask/detect-provider'

// UI
import useStyles from './styles';
import amuroLogo from '../images/Amuro_logo_wip_banner_cropped.png';
import upwardVector from '../images/upward_vector.svg';
import downwardVector from '../images/downward_vector.svg';
import mobileDownwardVector from '../images/mobile_downward_vector.svg';
import CustomToolTip from './CustomTooltip';

// Others
import { isMobile } from 'react-device-detect';

// Firebase
import FirebaseSignIn from '../signin/FirebaseSignIn';
import firebase from 'firebase/compat/app';

// Project
import { getAllOwnedTokens, getCustodianWalletAddress, getSigningMessage } from '../../controllers/amuro_services';
import { assertCurrentNetwork, getNetworkInfo } from '../../utils/blockchain_utils';
import { requestSignMessage, requestConnectMetamask } from '../../utils/metamask_utils';
import { UserContext, UserDispatchContext } from '../../context/UserProfile';

export default function ClaimWallet() {
    const { ethereum } = window;

    const [custodianWallet, setCustodianWallet] = React.useState(null);
    const [nativeWallet, setNativeWallet] = React.useState(null);
    const [ownedTokens, setOwnedTokens] = React.useState(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [wrongChainDialogOpen, setWrongChainDialogOpen] = React.useState(false);
    const [redemptionFailDialogOpen, setRedemptionFailDialogOpen] = React.useState(false);
    const [redemptionFailCustomDialogOpen, setRedemptionFailCustomDialogOpen] = React.useState(false);
    const [redemptionFailCustomMessage, setRedemptionFailCustomMessage] = React.useState(null);
    const [redemptionCode, setRedemptionCode] = React.useState('');
    const [redemptionTransactionHash, setRedemptionTransactionHash] = React.useState(null);
    const [explorerUrl, setExplorerUrl] = React.useState(null);
    const [openseaUrl, setOpenseaUrl] = React.useState(null);
    const [networkInfo, setNetworkInfo] = React.useState({ name: null, chain_id: null });
    const [isMetamaskDetected, setIsMetamaskDetected] = React.useState(false);
    const [signedMessageCredentials, setSignedMessageCredentials] = React.useState(null);

    const [showFirebaseSignIn, setShowFirebaseSignIn] = React.useState(true);
    const [firebaseUser, setFirebaseUser] = React.useState(null);
    const [metamaskButtonText, setMetamaskButtonText] = React.useState('Connect to Metamask');

    const themedStyles = useStyles();
    const theme = useTheme();
    const isMobileForUI = useMediaQuery(theme.breakpoints.down('md'));

    const handleSubmit = async (event) => {
        setIsSubmitting(true);
        event.preventDefault();
        if (true) {
            setWrongChainDialogOpen(true);
            setIsSubmitting(false);
            return;
        }
        if (!firebaseUser && !(await assertCurrentNetwork(ethereum, networkInfo.chain_id))) {
            // Assume login using Firebase and Metamask are mutually exclusive.
            // If we have a Firebase user instance, we don't need to check Metamask.
            setWrongChainDialogOpen(true);
            setIsSubmitting(false);
            return;
        };
        let address = nativeWallet;
        console.log({
            native_wallet: nativeWallet
        });
        try {
        } catch (err) {
            let errorCode = err.response.data.error.error_code;
            if (errorCode) {
                console.log(`Request failed with ${errorCode}`);
                switch (errorCode) {
                    case 6:
                        setRedemptionFailCustomMessage(`The redemption code "${redeemCode}" is incorrect (No entry is found in server)`);
                        setRedemptionFailCustomDialogOpen(true);
                        break;
                    case 28:
                        setRedemptionFailCustomMessage('The redemption code is already used');
                        setRedemptionFailCustomDialogOpen(true);
                        break;
                    case 22:
                        setRedemptionFailCustomMessage('The redemption is not ready or already used');
                        setRedemptionFailCustomDialogOpen(true);
                        break;
                    default:
                        setRedemptionFailDialogOpen(true);
                        break;
                }
            } else {
                setRedemptionFailDialogOpen(true);
            }
        }
        setIsSubmitting(false);
    };

    const openLink = (url) => {
        window.open(url);
    }

    const openTransactionDetails = () => {
        if (explorerUrl) {
            openLink(explorerUrl);
        }
    }

    const handleCloseDialog = (event, reason) => {
        // Do not close the dialog when it is backdrop click
        if (reason !== 'backdropClick') {
            setDialogOpen(false);
            if (openseaUrl) {
                openLink(openseaUrl);
            }
        }
    };

    const closeWrongChainDialog = () => {
        setWrongChainDialogOpen(false);
    }

    const connectMetamask = React.useCallback(async (event) => {
        event.preventDefault();
        console.log(`Trying to connect wallet`);
        if (isMetamaskDetected) {
            let result = await requestConnectMetamask(ethereum, networkInfo);
            if (result) {
                let encodedWalletCredentials = await requestSignMessage(window.ethereum);
                if (encodedWalletCredentials) {
                    setNativeWallet(result);
                }
                setSignedMessageCredentials(encodedWalletCredentials);
            }
        } else {
            // Metamask is not detected
            if (isMobile) {
                // Try to trigger Metamask app
                console.log(`Trying to trigger Metamask app`);
                let url = `https://metamask.app.link/dapp/${process.env.REACT_APP_METAMASK_DAPP_HOST}/redeem`;
                if (redemptionCode && redemptionCode.length > 0) {
                    url = `${url}/${redemptionCode}`;
                }
                openLink(url);
            }
        }

    }, [redemptionCode, isMetamaskDetected, networkInfo]);

    const logOutFirebase = async () => {
        setCustodianWallet(null);
        setNativeWallet(null);
        setOwnedTokens(null);
        if (firebaseUser) {
            await firebase.auth().signOut();
        }
        console.log("logged out");
    }

    const disconnectNativeWallet = async () => {
        setNativeWallet(null);
    }

    React.useEffect(() => {
        if (isMetamaskDetected) {
            setMetamaskButtonText(isMobile ? `Metamask wallet` : `Connect to Metamask`);
            if (isMobile) {
                // Do not allow Firebase sign-in within Metamask app,
                // it does not support at the moment
                setShowFirebaseSignIn(false);
            }
        } else {
            if (isMobile) {
                setMetamaskButtonText(`Open Metamask app`);
            }
        }
    }, [isMetamaskDetected])

    React.useEffect(() => {
        async function detectMetamask() {
            const provider = await detectEthereumProvider();
            if (provider) {
                console.log('Ethereum successfully detected!')
                // From now on, this should always be true:
                // provider === window.ethereum

                // Access the decentralized web!

                // Legacy providers may only have ethereum.sendAsync
                const chainId = await provider.request({
                    method: 'eth_chainId'
                });
                setIsMetamaskDetected(true);
            } else {
                window.addEventListener('ethereum#initialized', handleEthereum, {
                    once: true,
                });

                // If the event is not dispatched by the end of the timeout,
                // the user probably doesn't have MetaMask installed.
                setTimeout(handleEthereum, 3000); // 3 seconds
                // if the provider is not detected, detectEthereumProvider resolves to null
                console.error('Please install MetaMask!');
                setIsMetamaskDetected(false);
            }
        };
        setNetworkInfo(getNetworkInfo());
        detectMetamask();
        const authStateListener = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
            if (firebaseUser) {
                let result = await getCustodianWalletAddress(await firebaseUser.getIdToken());
                if (result && result.address) {
                    setCustodianWallet(result.address);
                    let tokens = await getAllOwnedTokens(await firebaseUser.getIdToken());
                    setOwnedTokens(tokens);
                } else {
                    console.log(`No custodian wallet address returned from server`);
                }
            }
        });

        return () => {
            authStateListener();
        }
    }, []);

    React.useEffect(() => {
        if (!nativeWallet) {
            setSignedMessageCredentials(null);
        }
    }, [nativeWallet]);

    async function handleEthereum() {
        console.log(`handleEthereum`);
        const provider = await detectEthereumProvider();
        console.log(`provider: ${provider}`);
        if (ethereum && ethereum.isMetaMask) {
            console.log('Ethereum successfully detected!');
            // Access the decentralized web!
        } else {
            console.log('Please install MetaMask!');
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container className={themedStyles.container}>
                <div className={themedStyles.redEllipse} />
                <div className={themedStyles.blueEllipse} />
                <img src={upwardVector} alt="upward_vector" className={themedStyles.upwardVector} />
                {
                    (isMobileForUI) ?
                        <img src={mobileDownwardVector} alt="downward_vector" className={themedStyles.downwardVector} /> :
                        <img src={downwardVector} alt="downward_vector" className={themedStyles.downwardVector} />
                }
                <CssBaseline />
                <Box className={themedStyles.mainContainer}>
                    <Typography className={themedStyles.developingTitleText}>
                        {process.env.REACT_APP_ENV == 'prod' ? 'Renovation in progress, stay tuned! We will come back with a new look :)' : 'WARNING! THIS IS TESTING ENVIRONMENT!'}
                    </Typography>
                    <img src={amuroLogo} alt="Logo" className={themedStyles.titleImage} />
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        className={themedStyles.contentBoxContainer}
                    >
                        {
                            /*
                                Sign-in custodian wallet
                            */
                            <div className={themedStyles.signInButtonContainer}>
                                <Typography className={themedStyles.titleText}>
                                    {`Request to transfer out assets from custodian wallet`}
                                </Typography>
                                {
                                    (!custodianWallet) ?
                                        <div>
                                            <Typography className={themedStyles.titleText}>
                                                {'Sign in your custodian wallet with:'}
                                            </Typography>
                                            {(showFirebaseSignIn) && <FirebaseSignIn />}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        }
                        {
                            /*
                                Signed-in custodian wallet
                            */
                            (custodianWallet) ?
                                <div className={themedStyles.submitContainer}>
                                    {firebaseUser ?
                                        /**
                                         * Firebase users - user display name
                                         */
                                        <Typography className={themedStyles.userConnectedStatusText}>
                                            {
                                                (isMobileForUI) ?
                                                    `User connected as ${firebaseUser.displayName}\n(Custodian wallet)` :
                                                    `User connected as ${firebaseUser.displayName} (Custodian wallet)`
                                            }
                                        </Typography>
                                        :
                                        null
                                    }
                                    {/**
                                         * Metamask users - native wallet info
                                         */
                                        <CustomToolTip tooltipText={custodianWallet}>

                                            <div className={themedStyles.walletStatusContainer}>

                                                <div className={themedStyles.walletStatusTextContainer}>
                                                    {
                                                        (custodianWallet) ?
                                                            <Typography className={themedStyles.walletStatusText}>
                                                                {
                                                                    (custodianWallet.length > 8) ?
                                                                        `Connected to ${custodianWallet.slice(0, 6)}...${custodianWallet.slice(-4)}` :
                                                                        `Connected to ${custodianWallet}`
                                                                }
                                                            </Typography> :
                                                            <Typography className={themedStyles.walletStatusText}>
                                                                {'Connecting...'}
                                                            </Typography>
                                                    }
                                                </div>

                                                <div className={themedStyles.walletStatusSeperator} />
                                                <Typography
                                                    className={themedStyles.disconnectText}
                                                    onClick={logOutFirebase}
                                                >
                                                    {`disconnect`}
                                                </Typography>
                                            </div>
                                        </CustomToolTip>
                                    }

                                    {ownedTokens ?
                                        (ownedTokens.length > 0) ?
                                            <Typography className={themedStyles.hasTokenStatusText}>
                                                {`You have ${ownedTokens.length} Amuro tokens in the custodian wallet`}
                                            </Typography>
                                            :
                                            <Typography className={themedStyles.hasNoTokenStatusText}>
                                                {`You have ${ownedTokens.length} Amuro tokens in the custodian wallet`}
                                            </Typography>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                        }
                        {
                            /**
                             * Native wallet not yet connected
                             */
                            (!nativeWallet) ?
                                <div className={themedStyles.signInButtonContainer}>
                                    <>
                                        <div className={themedStyles.signInSeperatorContainer}>
                                            <div className={themedStyles.seperatorStroke} />
                                            <Typography className={themedStyles.signInSeperatorText}>
                                                {'Connect the wallet you want to transfer your NFTs to:'}
                                            </Typography>
                                            <div className={themedStyles.seperatorStroke} />
                                        </div>
                                    </>
                                    <Button
                                        disabled={!ownedTokens || (ownedTokens.length == 0)}
                                        onClick={connectMetamask}
                                        variant="contained"
                                        className={themedStyles.metaMaskButton}
                                    >
                                        <Typography className={themedStyles.metaMaskText}>{metamaskButtonText}</Typography>
                                    </Button>
                                </div> : null
                        }
                        {
                            /**
                             * Native wallet connected
                             */
                            (nativeWallet) ?
                                <div className={themedStyles.signInButtonContainer}>
                                    <>
                                        <div className={themedStyles.signInSeperatorContainer}>
                                            <div className={themedStyles.seperatorStroke} />
                                            <Typography className={themedStyles.signInSeperatorText}>
                                                {'Transfer to the following wallet:'}
                                            </Typography>
                                            <div className={themedStyles.seperatorStroke} />
                                        </div>
                                        <CustomToolTip tooltipText={nativeWallet}>
                                            <div className={themedStyles.walletStatusContainer}>
                                                <div className={themedStyles.walletStatusTextContainer}>
                                                    {
                                                        (nativeWallet) ?
                                                            <Typography className={themedStyles.walletStatusText}>
                                                                {
                                                                    (nativeWallet.length > 8) ?
                                                                        `Connected to ${nativeWallet.slice(0, 6)}...${nativeWallet.slice(-4)}` :
                                                                        `Connected to ${nativeWallet}`
                                                                }
                                                            </Typography> :
                                                            <Typography className={themedStyles.walletStatusText}>
                                                                {'Connecting...'}
                                                            </Typography>
                                                    }
                                                </div>

                                                <div className={themedStyles.walletStatusSeperator} />
                                                <Typography
                                                    className={themedStyles.disconnectText}
                                                    onClick={disconnectNativeWallet}
                                                >
                                                    {`disconnect`}
                                                </Typography>
                                            </div>
                                        </CustomToolTip>
                                    </>
                                </div> : null
                        }
                        {
                            <div className={themedStyles.submitContainer}>
                                <Button
                                    disabled={!custodianWallet || !nativeWallet || isSubmitting}
                                    type="Submit"
                                    variant="contained"
                                    className={themedStyles.submitButton}
                                >
                                    {
                                        isSubmitting ?
                                            <CircularProgress color={'secondary'} size={14} /> :
                                            <Typography className={themedStyles.submitButtonText}>
                                                {"Submit"}
                                            </Typography>
                                    }
                                </Button>
                            </div>
                        }
                    </Box>
                </Box>
                <Dialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Redemption submitted`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Redemption code: ${redemptionCode}`}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            It will take a few minutes to process the redemption. You may check your wallet later.
                        </DialogContentText>
                        {redemptionTransactionHash &&
                            <DialogContentText id="alert-dialog-description">
                                {`Transaction hash: ${redemptionTransactionHash}`}
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions>
                        {explorerUrl && <Button onClick={openTransactionDetails}>Transaction details</Button>}
                        <Button onClick={handleCloseDialog}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={wrongChainDialogOpen}
                    onClose={closeWrongChainDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Feature not ready`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`The feature is not ready yet, stay tuned.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeWrongChainDialog}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={redemptionFailDialogOpen}
                    onClose={() => setRedemptionFailDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Something went wrong :(`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Redemption error. It may be due to
                        </DialogContentText>
                        <DialogContentText>
                            1. The redemption code is incorrect
                        </DialogContentText>
                        <DialogContentText>
                            2. The redemption is already redeemed or expired
                        </DialogContentText>
                        <DialogContentText>
                            3. Other server errors
                        </DialogContentText>
                        <DialogContentText>
                            Please try again later.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setRedemptionFailDialogOpen(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={redemptionFailCustomDialogOpen}
                    onClose={() => setRedemptionFailCustomDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Something went wrong :(`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {redemptionFailCustomMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setRedemptionFailCustomDialogOpen(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </ThemeProvider >
    );
}
