import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import logo from './components/home_page/modules/images/Amuro_logo_wip_banner.png';
import SignIn from './components/signin/SignIn';
import TestRedemptionCodes from './components/redeem/TestRedemptionCodes';
import ApiKey from './components/developer/ApiKey';
import CopyrightFooter from './components/copyright_footer';
import RedeemMain from './components/redeem';
import RedeemMainCX from './components/redeem_cx';
import ClaimWallet from './components/claim_wallet/ClaimWallet';
import LoyaltyMain from './components/loyalty';
import CampaignSummary from './components/campaign_summary/CampaignSummary';
import CmiRedeemMain from './components/cmi';
import AdminCheatSheet from './components/admin_cheat_sheet/AdminCheatSheet';

const CX_SUBDOMAIN = [
    'cathaypacific',
    'cathay',
    'cx'
]

const CMI_SUBDOMAIN = [
    'fimmick',
]

export default function App() {
    console.log(`env: ${process.env.REACT_APP_ENV}`);
    if (process.env.REACT_APP_ENV === 'prod') {
        console.log = function () { };
    }
    let hostComponents = window.location.host.split(".");
    let subDomain = hostComponents[0];
    if (subDomain && CX_SUBDOMAIN.includes(subDomain)) {
        return <Container
            maxWidth="false"
            style={{
                padding: 0,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<RedeemMainCX />} />
                </Routes>
            </BrowserRouter>
        </Container>
    } else if (subDomain && CMI_SUBDOMAIN.includes(subDomain)) {
        return <Container
            maxWidth="false"
            style={{
                padding: 0,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<CmiRedeemMain />} />
                </Routes>
            </BrowserRouter>
        </Container>
    } else {
        return (
            <Container
                maxWidth="false"
                style={{
                    padding: 0,
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<RedeemMain />} />
                        <Route path="claim-wallet" element={<ClaimWallet />} />
                        <Route path="redeem" element={<RedeemMain />} />
                        <Route path='redeem/:prefilledCode' element={<RedeemMain />} />
                        <Route path="redeem-cx" element={<RedeemMainCX />} />
                        <Route path='redeem-cx/:prefilledCode' element={<RedeemMainCX />} />
                        <Route path='redeem-cmi' element={<CmiRedeemMain />} />
                        <Route path="wallet" element={<SignIn />} />
                        <Route path="codes" element={<TestRedemptionCodes />} />
                        <Route path="cx-codes" element={<TestRedemptionCodes campaignId={6} contractAddress={"0x3109eB8Ea788530D55290CF1422Bebca0BAe07eb"} />} />
                        <Route path="developer" element={<ApiKeyMain />} />
                        <Route path="member" element={<LoyaltyMain />} />
                        <Route path="campaign-summary" element={<CampaignSummary />} />
                        <Route path="cheat" element={<AdminCheatSheet />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </Container>
        );
    }
}

function ApiKeyMain() {
    return (
        <Container>
            <ApiKey />
            <CopyrightFooter />
        </Container>
    );
}

function Home2() {
    console.log(logo);

    const styles = {
        // "display": "flex",
        // "justify-content": "center",
        // backgroundColor: "#FFF",
        margin: "auto",
        width: "100%"
    }

    return (
        <Container maxWidth="false" disableGutters={true} style={{ backgroundColor: "#f4f4f4" }}>
            <img src={logo} alt="Logo" style={styles} />
            <Container maxWidth="md">
                <Typography variant="h5" align='center' sx={{
                    fontFamily: 'Arial',
                    color: "#3266C2"
                }}>
                    Leave us your email address to stay updated:
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <TextField fullWidth={true} placeholder="Email address" id="email-address" variant="standard" />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained">Submit</Button>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    )
}

function NotFound() {
    return <h1>Page Not Found</h1>
}
