import * as React from 'react';

import { UserProvider } from '../../context/UserProfile';

import Redeem from './Redeem';
import RedeemBackground from './RedeemBackground';
import AccessControl from '../access_control/AccessControl';
import CopyrightFooter from '../copyright_footer';
import Gallery from '../gallery/Gallery';

const RedeemMain = () => {
    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            background: '#fff',
        }}>
            <UserProvider>
                <RedeemBackground />
                <Redeem />
                {
                    process.env.REACT_APP_ENV != 'prod' ?
                        <AccessControl /> : null
                }
                <Gallery />
                <CopyrightFooter />
            </UserProvider >
        </div>
    )
}

export default RedeemMain;
