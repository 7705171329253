
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles'

import firebase from 'firebase/compat/app';
import theme from '../../theme';

import FirebaseSignIn from '../signin/FirebaseSignIn';
import { developerLogin } from '../../controllers/amuro_services';
import amuroLogo from '../images/Amuro_logo_wip_banner_cropped.png';

export default function ApiKey() {
    const [firebaseUser, setFirebaseUser] = React.useState(null);
    const [apiKey, setApiKey] = React.useState(null);

    const logo_styles = {
        display: "block",
        margin: "auto",
        width: "100%",
        textColor: "#3266C2",
    }

    const logOut = async () => {
        if (firebaseUser) {
            await firebase.auth().signOut();
        }
        console.log("logged out");
    }

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
            if (firebaseUser) {
                let result = await developerLogin(await firebaseUser.getIdToken());
                console.log(`developerLogin result: ${JSON.stringify(result)}`);
                setApiKey(result.api_key);
            }
        })
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        alignItems: 'center',
                        justifyContent: "center"
                    }}
                >
                    <img src={amuroLogo} alt="Logo" style={logo_styles} />
                    {!firebaseUser && <Typography component="h1" variant="h5">Sign-in / Register as developer</Typography>}
                    {!firebaseUser && <FirebaseSignIn overrideSignInOptions={[firebase.auth.GoogleAuthProvider.PROVIDER_ID]} />}

                    {firebaseUser && <Typography component="h1" variant="h5">Developer signed-in as {firebaseUser.displayName}</Typography>}
                    {firebaseUser && <Typography variant="body1" sx={{ "margin-top": "10px"}}>API key:</Typography>}
                    {firebaseUser && !apiKey && <Typography variant="body1">Fetching API key</Typography>}
                    {firebaseUser && apiKey && <Typography variant="body1">{apiKey}</Typography>}
                    {firebaseUser && <Button variant='contained' onClick={logOut} sx={{ "my": "10px"}}>Sign out</Button>}
                </Box>
            </Container>
        </ThemeProvider>
    );
}
