import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { useMoralis } from "react-moralis";

// Controllers
import { findWallet } from '../../controllers/amuro_services';

// Utils
import { isMetamaskInstalled as checkIfMetamaskInstalled } from '../../utils/blockchain_utils';
import { getMetamaskInstallUrl } from '../../utils/metamask_utils';
import { requireValues } from './input_utils';

const theme = createTheme();

export default function RetrieveWallet(props) {
    const { authenticate, isAuthenticated, isAuthenticating, user, account, logout } = useMoralis();
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [passwordInput, setPasswordInput] = React.useState(null);
    const [walletInfo, setWalletInfo] = React.useState(null);
    const [showMetaMaskInstallComponent, setShowMetaMaskInstallComponent] = React.useState(false);

    const enableSubmitButton = React.useMemo(() => {
        if (props.firebaseUser == null) {
            return false;
        }
        if (passwordInput == null || passwordInput.length == 0) {
            return false;
        }
        return true;
    }, [props.firebaseUser, passwordInput]);

    const openLink = (url) => {
        console.log(`openLink triggered`);
        window.open(url);
    }

    const onRetrieveWalletInfoButtonClick = async (event) => {
        event.preventDefault();
        console.log(`onRetrieveWalletInfoButtonClick`);
        if (props.firebaseUser) {
            let firebaseJwtToken = await props.firebaseUser.getIdToken();
            let password = passwordInput;
            let wallet = await findWallet(firebaseJwtToken, password);
            setWalletInfo(wallet);
            if (!wallet) {
                setErrorMessage(`No wallet found`);
            }
        } else {
            console.log(`props.firebaseUser is null, ${props.firebaseUser}`);
        }
    }

    const onConnectMetaMaskClick = async (event) => {
        event.preventDefault();
        if (checkIfMetamaskInstalled) {
            await metamaskLogin();
        } else {
            setShowMetaMaskInstallComponent(true);
        }
    }

    const metamaskLogin = async () => {
        if (!isAuthenticated) {
  
          await authenticate()
            .then(function (user) {
              console.log(user.get("ethAddress"));
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }

    const getInstallMetaMaskComponent = () => {
        if (isMobile) {
            return <Typography>
                {`Please `}
                <Link onClick={() => { openLink(getMetamaskInstallUrl(isMobile)) }} variant="body1">install MetaMask</Link>
                {` to proceed if you have not.`}
            </Typography>
        } else {
            return <Typography>
                {`MetaMask is not detected, you need to `}
                <Link onClick={() => { openLink(getMetamaskInstallUrl(isMobile)) }} variant="body1">install MetaMask</Link>
                {` to proceed`}
            </Typography>
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container>
                {
                    requireValues([props.firebaseUser]) &&
                    <Container>
                        <Typography>
                            Enter one of your passwords to retrieve your wallet info:
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password to retrieve wallet"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(event) => { setPasswordInput(event.target.value) }} />
                    </Container>
                }
                {
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={onRetrieveWalletInfoButtonClick}
                        sx={{ mt: 3, mb: 2 }}>
                        Retrieve wallet info
                    </Button>
                }
                {walletInfo &&
                    <Container>
                        <Typography variant="h5" >{`Your wallet info:`}</Typography>
                        <Typography>{`Wallet address: "${walletInfo.address}"`}</Typography>
                        <Typography>{`private key: "${walletInfo.private_key}"`}</Typography>
                        <Typography>{`mnemonic: "${walletInfo.mnemonic}"`}</Typography>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={onConnectMetaMaskClick}
                            sx={{ mt: 3, mb: 2 }}>
                            Connect to MetaMask
                        </Button>
                    </Container>
                }
                {showMetaMaskInstallComponent && getInstallMetaMaskComponent()}
            </Container>
        </ThemeProvider>
    );
}