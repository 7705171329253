
import * as React from 'react';

// MUI
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

// styles
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme';
import useStyles from './styles';

// Others
import firebase from 'firebase/compat/app';
import { QrReader } from 'react-qr-reader';

// first party
import { requestAccessByJwt as requestAccessService, requestAccessByNativeWallet } from '../../controllers/amuro_services';
import { isSameAddress } from '../../utils/blockchain_utils';
import { requestSignMessage } from '../../utils/metamask_utils';

// Context
import { UserContext } from '../../context/UserProfile';

export default function AccessControl() {

    // Context
    const userDetails = React.useContext(UserContext);

    const [firebaseUser, setFirebaseUser] = React.useState(null);
    const themedStyles = useStyles();

    // UI
    const [showQrReader, setShowQrReader] = React.useState(false);
    const [showAccessResult, setShowAccessResult] = React.useState(false);
    const [accessGranted, setAccessGranted] = React.useState(false);
    const accessCodeRef = React.useRef(null);

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
        })
    }, []);

    const onQrReaderClick = (event) => {
        setShowQrReader(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAccessResult(false);
    };

    const signRequestAccessMessage = async () => {
        const messageToSign = 'Sign this message to verify your ownership to request access';
        let [message, signature, address] = await requestSignMessage(window.ethereum, messageToSign);
        if (isSameAddress(address, userDetails.wallet)) {
            return [message, signature, address];
        } else {
            console.log(`Signer address is not the same as the registered wallet`);
            return [message, null, null];
        }
    }

    const requestAccess = async () => {
        let accessCode = accessCodeRef.current;
        let accessGranted = false;
        if (firebaseUser) {
            accessGranted = await requestAccessService(await firebaseUser.getIdToken(), accessCode);
        } else {
            let [message, signature, address] = await signRequestAccessMessage();
            if (signature && address) {
                accessGranted = await requestAccessByNativeWallet(address, message, signature);
            }
        }
        if (accessGranted && accessGranted.access) {
            setAccessGranted(true);
        } else {
            setAccessGranted(false);
        }
        setShowAccessResult(true);
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" sx={{ position: 'absolute', bottom: '3rem', zIndex: 2 }}>
                <CssBaseline />
                <Box
                    // className={themedStyles.contentContainer}
                    sx={{
                        alignItems: 'center',
                        justifyContent: "center"
                    }}
                >
                    {userDetails.wallet && <Button className={themedStyles.requestAccessButton} variant='contained' onClick={onQrReaderClick} >Scan Access code (testing feature)</Button>}
                    {
                        showQrReader &&
                        <QrReader
                            onResult={(result, error) => {
                                if (result) {
                                    console.log(`QR code found: ${result.text}`);
                                    setShowQrReader(false);
                                    accessCodeRef.current = result.text;
                                    requestAccess();
                                }
                                if (error) {
                                    console.info(error);
                                }
                            }}
                            style={{ width: '100%' }}
                            constraints={{ facingMode: 'environment' }}
                        />
                    }
                    {
                        showAccessResult &&
                        (
                            accessGranted ?
                                <Alert severity="success">Access granted</Alert>
                                :
                                <Alert severity="error">Access denied</Alert>
                        )
                    }
                    {
                        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={showAccessResult} autoHideDuration={8000} onClose={handleClose}>
                            {
                                accessGranted ?
                                    <Alert severity="success">Access granted</Alert>
                                    :
                                    <Alert severity="error">Access denied</Alert>
                            }
                        </Snackbar>
                    }
                </Box>
            </Container>
        </ThemeProvider>
    );
}
