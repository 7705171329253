import * as React from 'react';
import { useParams } from 'react-router-dom';

// MUI
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme, } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles'
import detectEthereumProvider from '@metamask/detect-provider'
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

// UI
import useStyles from './styles';
import amuroLogo from '../images/Amuro_logo_wip_banner_cropped.png';
import cathayLogo from '../images/cathay_logo.svg';
import CustomToolTip from './CustomTooltip';

// Others
import { QrReader } from 'react-qr-reader';

// Firebase
import FirebaseSignIn from '../signin/FirebaseSignIn';
import firebase from 'firebase/compat/app';

// Project
import { redeem, getCustodianWalletAddress } from '../../controllers/amuro_services';
import { explorerUrl as explorerUrlPrefix, assertCurrentNetwork, getNetworkInfo } from '../../utils/blockchain_utils';
import { requestConnectMetamask } from '../../utils/metamask_utils';
import { UserContext, UserDispatchContext } from '../../context/UserProfile';
import * as MetamaskUtils from '../../utils/metamask_utils';
import { requestSignMessage, getMetamaskInstallStatus } from '../../utils/metamask_utils';

const STEP_REDEEM_NFT = 'redeem_nft';
const STEP_REDEEMED_NFT = 'redeemed_nft';
const STEP_RDEEEM_EXPERIENCE = 'redeem_experience';
const STEPS = [STEP_REDEEM_NFT, STEP_REDEEMED_NFT, STEP_RDEEEM_EXPERIENCE]

export default function RedeemCX() {

    // Context
    const userDetails = React.useContext(UserContext);
    const setUserDetails = React.useContext(UserDispatchContext);

    const { prefilledCode } = useParams();
    const { ethereum } = window;

    const [wallet, setWallet] = React.useState(null);
    const [signedMessageCredentials, setSignedMessageCredentials] = React.useState(null);
    const [walletExplorerUrl, setWalletExplorerUrl] = React.useState(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isFetchingCustodianWalletInfo, setIsFetchingCustodianWalletInfo] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [wrongChainDialogOpen, setWrongChainDialogOpen] = React.useState(false);
    const [redemptionFailDialogOpen, setRedemptionFailDialogOpen] = React.useState(false);
    const [redemptionFailCustomDialogOpen, setRedemptionFailCustomDialogOpen] = React.useState(false);
    const [redemptionFailCustomMessage, setRedemptionFailCustomMessage] = React.useState(null);
    const [redemptionCode, setRedemptionCode] = React.useState('');
    const [redemptionTransactionHash, setRedemptionTransactionHash] = React.useState(null);
    const [explorerUrl, setExplorerUrl] = React.useState(null);
    const [openseaUrl, setOpenseaUrl] = React.useState(null);
    const redemptionCodeInput = React.useRef(null);
    const redemptionChainId = React.useRef(80001);
    const [networkInfo, setNetworkInfo] = React.useState({ name: null, chain_id: null });
    const [showQrReader, setShowQrReader] = React.useState(false);
    const [metamaskInstallStatus, setMetamaskInstallStatus] = React.useState(MetamaskUtils.METAMASK_STATUS_UNKNOWN);
    const [currentStep, setCurrentStep] = React.useState(STEP_REDEEM_NFT);

    const [showFirebaseSignIn, setShowFirebaseSignIn] = React.useState(true);
    const [firebaseUser, setFirebaseUser] = React.useState(null);
    const [metamaskButtonText, setMetamaskButtonText] = React.useState('Detecting Metamask...');

    const themedStyles = useStyles();
    const theme = useTheme();
    const isMobileForUI = useMediaQuery(theme.breakpoints.down('md'));

    const handleSubmit = async (event) => {
        event.preventDefault();
        switch (currentStep) {
            case STEP_REDEEMED_NFT:
                setCurrentStep(STEP_RDEEEM_EXPERIENCE);
                return;
            case STEP_RDEEEM_EXPERIENCE:
                setCurrentStep(STEP_REDEEM_NFT)
                return;
        }
        setIsSubmitting(true);
        if (!firebaseUser && !(await assertCurrentNetwork(ethereum, networkInfo.chain_id))) {
            // Assume login using Firebase and Metamask are mutually exclusive.
            // If we have a Firebase user instance, we don't need to check Metamask.
            setWrongChainDialogOpen(true);
            setIsSubmitting(false);
            return;
        };
        let redeemCode = redemptionCode
        if (redeemCode.length == 0) {
            return;
        }
        let address = wallet;
        console.log({
            address: address,
            redeem_code: redeemCode,
        });
        try {
            let firebaseJwt = firebaseUser ? await firebaseUser.getIdToken() : null;
            let tx = await redeem(firebaseJwt, signedMessageCredentials, redeemCode, address);
            console.log(`tx: ${JSON.stringify(tx)}`);
            if (tx) {
                redemptionChainId.current = tx.chainId;
                setRedemptionTransactionHash(tx.hash);
                if (tx.hash) {
                    setExplorerUrl(`${explorerUrlPrefix(tx.chainId)}/tx/${tx.hash}`);
                }
                if (tx.opensea_url) {
                    setOpenseaUrl(tx.opensea_url);
                }
                redemptionCodeInput.current.value = "";
                setDialogOpen(true);
                setCurrentStep(STEP_REDEEMED_NFT);
            } else {
                setRedemptionFailDialogOpen(true);
            }
        } catch (err) {
            console.log(err);
            let errorCode = err.response.data.error.error_code;
            if (errorCode) {
                console.log(`Request failed with ${errorCode}`);
                switch (errorCode) {
                    case 6:
                        setRedemptionFailCustomMessage(`The redemption code "${redeemCode}" is incorrect (No entry is found in server)`);
                        setRedemptionFailCustomDialogOpen(true);
                        break;
                    case 28:
                        setRedemptionFailCustomMessage('The redemption code is already used');
                        setRedemptionFailCustomDialogOpen(true);
                        break;
                    case 22:
                        setRedemptionFailCustomMessage('The redemption is not ready or already used');
                        setRedemptionFailCustomDialogOpen(true);
                        break;
                    default:
                        setRedemptionFailDialogOpen(true);
                        break;
                }
            } else {
                setRedemptionFailDialogOpen(true);
            }
        }
        setIsSubmitting(false);
    };

    const openLink = (url) => {
        window.open(url);
    }

    const openTransactionDetails = () => {
        if (explorerUrl) {
            openLink(explorerUrl);
        }
    }
    const openOpenseaUrl = () => {
        if (openseaUrl) {
            openLink(openseaUrl);
        }
    }

    const handleCloseDialog = (event, reason) => {
        // Do not close the dialog when it is backdrop click
        if (reason !== 'backdropClick') {
            setDialogOpen(false);
        }
    };

    const closeWrongChainDialog = () => {
        setWrongChainDialogOpen(false);
    }

    React.useEffect(() => {
        if (wallet) {
            setWalletExplorerUrl(`${explorerUrlPrefix(networkInfo.chain_id)}/address/${wallet}`);
        } else {
            setWalletExplorerUrl(null);
        }
    }, [wallet])

    const connectMetamask = React.useCallback(async (event) => {
        event.preventDefault();
        console.log(`Trying to connect wallet`);
        switch (metamaskInstallStatus) {
            case MetamaskUtils.METAMASK_STATUS_INSTALLED_WEB:
            case MetamaskUtils.METAMASK_STATUS_INSTALLED_MOBILE:
                let result = await requestConnectMetamask(ethereum, networkInfo);
                if (result) {
                    let encodedWalletCredentials = await requestSignMessage(window.ethereum);
                    if (encodedWalletCredentials) {
                        setWallet(result);
                        setUserDetails({
                            wallet: result
                        });
                    }
                    setSignedMessageCredentials(encodedWalletCredentials);
                }
                break;
            case MetamaskUtils.METAMASK_STATUS_REDIRECT_TO_METAMASK_APP:
                // Try to trigger Metamask app
                console.log(`Trying to trigger Metamask app`);
                let url = `https://metamask.app.link/dapp/${process.env.REACT_APP_METAMASK_DAPP_HOST}/redeem`;
                if (redemptionCode && redemptionCode.length > 0) {
                    url = `${url}/${redemptionCode}`;
                }
                openLink(url);
                break;
            case MetamaskUtils.METAMASK_STATUS_ASK_TO_INSTALL_METAMASK_WEB_EXTENSION:
                let metamaskWebsiteUrl = `https://metamask.io/download/`;
                openLink(metamaskWebsiteUrl);
                break;
            default:
                console.log(`connectMetamask, Unknown Metamask install status`);
                break;
        }
    }, [redemptionCode, metamaskInstallStatus, networkInfo]);

    const logOut = async () => {
        setWallet(null);
        setUserDetails({
            wallet: null
        })
        setSignedMessageCredentials(null);
        if (firebaseUser) {
            await firebase.auth().signOut();
        }
        console.log("logged out");
    }

    const onQrReaderClick = (shouldOpen) => {
        setShowQrReader(shouldOpen);
    }

    React.useEffect(() => {
        console.log(`installStatus: ${metamaskInstallStatus}`);
        switch (metamaskInstallStatus) {
            case MetamaskUtils.METAMASK_STATUS_UNKNOWN:
                setMetamaskButtonText(`Detecting Metamask...`);
                break;
            case MetamaskUtils.METAMASK_STATUS_INSTALLED_WEB:
                setMetamaskButtonText(`Connect to Metamask`);
                break;
            case MetamaskUtils.METAMASK_STATUS_INSTALLED_MOBILE:
                // Do not allow Firebase sign-in within Metamask app,
                // it does not support at the moment
                setShowFirebaseSignIn(false);
                setMetamaskButtonText(`Metamask wallet`);
                break;
            case MetamaskUtils.METAMASK_STATUS_REDIRECT_TO_METAMASK_APP:
                setMetamaskButtonText(`Open Metamask app`);
                break;
            case MetamaskUtils.METAMASK_STATUS_ASK_TO_INSTALL_METAMASK_WEB_EXTENSION:
                setMetamaskButtonText(`Install Metamask extension`);
                break;
            default:
                console.log(`Unknown Metamask install status`);
                break;
        }
    }, [metamaskInstallStatus])

    React.useEffect(() => {
        async function detectMetamask() {
            let metamaskStatus = await getMetamaskInstallStatus(window);
            console.log(`metamaskStatus: ${metamaskStatus}`);
            setMetamaskInstallStatus(metamaskStatus);
        };
        setNetworkInfo(getNetworkInfo());
        detectMetamask();
        const authStateListener = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
            if (firebaseUser) {
                setIsFetchingCustodianWalletInfo(true);
                let result = await getCustodianWalletAddress(await firebaseUser.getIdToken());
                setIsFetchingCustodianWalletInfo(false);
                if (result && result.address) {
                    setWallet(result.address);
                    setUserDetails({
                        wallet: result.address
                    })
                } else {
                    console.log(`No custodian wallet address returned from server`);
                }
            }
        });
        if (prefilledCode && prefilledCode.length > 0) {
            setRedemptionCode(prefilledCode);
        }

        return () => {
            authStateListener();
        }
    }, []);

    async function handleEthereum() {
        console.log(`handleEthereum`);
        const provider = await detectEthereumProvider();
        console.log(`provider: ${provider}`);
        if (ethereum && ethereum.isMetaMask) {
            console.log('Ethereum successfully detected!');
            // Access the decentralized web!
        } else {
            console.log('Please install MetaMask!');
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container className={themedStyles.container}>
                <CssBaseline />
                <Box className={themedStyles.mainContainer}>
                    <Typography className={themedStyles.developingTitleText}>
                        {process.env.REACT_APP_ENV == 'prod' ? 'Renovation in progress, stay tuned! We will come back with a new look :)' : 'WARNING! THIS IS TESTING ENVIRONMENT!'}
                    </Typography>
                    <img src={cathayLogo} alt="Logo" className={themedStyles.titleImage} />
                    <Box className={themedStyles.stepContainer}>
                        {/* <div className={themedStyles.dottedStroke} /> */}
                        {
                            STEPS.map((step, idx, arr) =>
                                <React.Fragment key={step}>
                                    <div className={themedStyles.stepTabContainer}>
                                        <Box className={themedStyles.stepNumberContainer} border={currentStep === step ? '3px solid rgba(51, 125, 120, 0.1)' : '0'} >
                                            <Box
                                                className={themedStyles.stepNumber}
                                                backgroundColor={currentStep === step ? '#006055' : 'rgba(51, 125, 120, 0.1)'}
                                                color={currentStep === step ? '#fff' : '#006055'}
                                            >
                                                {
                                                    (idx + 1).toLocaleString('en-US', {
                                                        minimumIntegerDigits: 2,
                                                        useGrouping: false
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                        <Typography
                                            className={themedStyles.stepText}
                                            color={currentStep === step ? '#808080' : '#A8A8A8'}
                                        >
                                            {step.replace('_', ' ')}
                                        </Typography>
                                    </div>
                                    {
                                        idx !== arr.length - 1 &&
                                        <div className={themedStyles.dottedStroke} />
                                    }
                                </React.Fragment>
                            )
                        }
                    </Box>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        className={themedStyles.contentBoxContainer}
                    >
                        {
                            /**
                             * Signed-in section
                             */
                            currentStep === STEP_REDEEM_NFT &&
                            <div className={themedStyles.submitContainer}>
                                <Typography className={themedStyles.titleText}>
                                    {'Redeem NFT with:'}
                                </Typography>
                                {firebaseUser ?
                                    /**
                                     * Firebase users - user display name
                                     */
                                    <Typography className={themedStyles.userConnectedStatusText}>
                                        {
                                            (isMobileForUI) ?
                                                `User connected as ${firebaseUser.displayName}\n(Custodian wallet)` :
                                                `User connected as ${firebaseUser.displayName} (Custodian wallet)`
                                        }
                                    </Typography>
                                    :
                                    null
                                }
                                {
                                    (!wallet) ?
                                        (isFetchingCustodianWalletInfo) ?
                                            <Typography className={themedStyles.fetchingCustodianWalletText}>
                                                {'Retrieving your wallet info, please wait...'}
                                            </Typography>
                                            :
                                            /**
                                             * Show sign-in options
                                             */
                                            <>
                                                <Button
                                                    variant='text'
                                                    color='secondary'
                                                    className={themedStyles.walletConnectButton}
                                                    onClick={connectMetamask}
                                                >
                                                    <Typography className={themedStyles.walletConnectButtonText}>
                                                        {metamaskButtonText}
                                                    </Typography>
                                                </Button>
                                                <div className={themedStyles.signInSeperatorContainer}>
                                                    <div className={themedStyles.seperatorStroke} />
                                                    <Typography className={themedStyles.signInSeperatorText}>
                                                        {'Or Sign in with'}
                                                    </Typography>
                                                    <div className={themedStyles.seperatorStroke} />
                                                </div>
                                                <FirebaseSignIn />
                                            </>
                                        :
                                        null
                                }
                                {   /**
                                     * Metamask users - native wallet info
                                     */
                                    (wallet) ?
                                        <CustomToolTip tooltipText={wallet} url={walletExplorerUrl}>
                                            {
                                                <div className={themedStyles.walletStatusContainer}>
                                                    <div className={themedStyles.walletStatusTextContainer}>
                                                        {
                                                            (wallet) ?
                                                                <Typography className={themedStyles.walletStatusText}>
                                                                    {
                                                                        (wallet.length > 8) ?
                                                                            `Connected to ${wallet.slice(0, 6)}...${wallet.slice(-4)}` :
                                                                            `Connected to ${wallet}`
                                                                    }
                                                                </Typography> :
                                                                <Typography className={themedStyles.walletStatusText}>
                                                                    {'Connecting...'}
                                                                </Typography>
                                                        }
                                                    </div>

                                                    <div className={themedStyles.walletStatusSeperator} />
                                                    <Typography
                                                        className={themedStyles.disconnectText}
                                                        onClick={logOut}
                                                    >
                                                        {`disconnect`}
                                                    </Typography>
                                                </div>
                                            }
                                        </CustomToolTip>
                                        :
                                        null
                                }
                                {
                                    (wallet) &&
                                    <div className={themedStyles.submitSeperatorContainer}>
                                        <div className={themedStyles.seperatorStroke} />
                                        <Typography className={themedStyles.submitSeperatorText}>
                                            {(isMobileForUI) ?
                                                'And enter your\nredemption code' :
                                                'And enter your redemption code'}
                                        </Typography>
                                        <div className={themedStyles.seperatorStroke} />
                                    </div>
                                }
                                {
                                    showQrReader &&
                                    <>
                                        <div className={themedStyles.qrCodeScannerContainer}>
                                            <QrReader
                                                onResult={(result, error) => {
                                                    if (result) {
                                                        redemptionCodeInput.current.value = result.text;
                                                        redemptionCodeInput.current.focus();
                                                        setRedemptionCode(result.text);
                                                        setShowQrReader(false);
                                                    }
                                                    if (error) {
                                                        console.info(error);
                                                    }
                                                }}
                                                videoContainerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                videoStyle={{ width: 'auto', left: 'auto' }}
                                                containerStyle={{ display: 'flex', flex: 1, width: '100%', alignItems: 'center' }}
                                                constraints={{ facingMode: 'environment' }}
                                            />
                                        </div>
                                        <Button
                                            variant='text'
                                            color='secondary'
                                            className={themedStyles.closeScannerButton}
                                            onClick={() => { onQrReaderClick(false) }}
                                        >
                                            <Typography className={themedStyles.closeScannerButtonText}>
                                                {'close camera'}
                                            </Typography>
                                        </Button>
                                    </>
                                }
                                {
                                    (wallet) &&
                                    <>
                                        <TextField
                                            required
                                            id="redeem_code"
                                            label="Redeem code"
                                            name="redeem_code"
                                            color='cathay'
                                            defaultValue={prefilledCode}
                                            className={themedStyles.redeemCodeInput}
                                            inputRef={redemptionCodeInput}
                                            onChange={(e) => {
                                                setRedemptionCode(e.target.value);
                                            }}
                                            InputProps={
                                                {
                                                    endAdornment:
                                                        <IconButton
                                                            color='cathay'
                                                            aria-label="upload picture"
                                                            component="span"
                                                            onClick={() => { onQrReaderClick(true) }}
                                                        >
                                                            <QrCodeScannerIcon />
                                                        </IconButton>
                                                }}
                                        />
                                        <Button
                                            disabled={!wallet || redemptionCode.length == 0 || isSubmitting}
                                            type="Submit"
                                            variant="contained"
                                            className={themedStyles.submitButton}
                                        >
                                            {
                                                isSubmitting ?
                                                    <CircularProgress color={'secondary'} size={14} /> :
                                                    <Typography className={themedStyles.submitButtonText}>
                                                        {"Submit"}
                                                    </Typography>
                                            }
                                        </Button>
                                    </>
                                }
                            </div>
                        }
                        {
                            currentStep === STEP_REDEEMED_NFT &&
                            <div className={themedStyles.submitContainer}>
                                <Typography className={themedStyles.titleText}>
                                    {'Congratulations!'}
                                </Typography>
                                <Typography className={themedStyles.contentText}>
                                    {`Congrats on redeeming your NFT! With this, you will have access to our premium lounge and services.Check`}
                                    <br /><br />
                                    {'Your Wallet!'}
                                </Typography>
                                <div className={themedStyles.submitSeperatorContainer}>
                                    <div className={themedStyles.seperatorStroke} />
                                    <Typography className={themedStyles.submitSeperatorText}>
                                        {'And'}
                                    </Typography>
                                    <div className={themedStyles.seperatorStroke} />
                                </div>
                                <Button
                                    type="Submit"
                                    variant="contained"
                                    className={themedStyles.submitButton}
                                >
                                    <Typography className={themedStyles.submitButtonText}>
                                        {"SEE HOW TO EXPERIENCE"}
                                    </Typography>
                                </Button>
                            </div>
                        }
                        {
                            currentStep === STEP_RDEEEM_EXPERIENCE &&
                            <div className={themedStyles.submitContainer}>
                                <Typography className={themedStyles.titleText}>
                                    {'Scan and Redeem Experience with the NFT'}
                                </Typography>
                                <Typography className={themedStyles.contentText}>
                                    {'Show your NFT and scan the qr code shown at lounge to redeem the perks!'}
                                </Typography>
                                <Button
                                    type="Submit"
                                    variant="contained"
                                    className={themedStyles.submitButton}
                                >
                                    <Typography className={themedStyles.submitButtonText}>
                                        {"BACK TO REDEEM PAGE"}
                                    </Typography>
                                </Button>
                            </div>
                        }
                    </Box>
                </Box>
                <Dialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Redemption submitted`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Redemption code: ${redemptionCode}`}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            It will take a few minutes to process the redemption. You may check your wallet later.
                        </DialogContentText>
                        {redemptionTransactionHash &&
                            <DialogContentText id="alert-dialog-description">
                                {`Transaction hash: ${redemptionTransactionHash}`}
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions>
                        {explorerUrl && <Button onClick={openTransactionDetails}>Transaction details</Button>}
                        {openseaUrl && <Button onClick={openOpenseaUrl}>View on Opensea</Button>}
                        <Button onClick={handleCloseDialog}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={wrongChainDialogOpen}
                    onClose={closeWrongChainDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Incorrect network`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Please switch your Metamask network to ${networkInfo.network_name} (chainId: ${networkInfo.chain_id}) to continue.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeWrongChainDialog}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={redemptionFailDialogOpen}
                    onClose={() => setRedemptionFailDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Something went wrong :(`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Redemption error. It may be due to
                        </DialogContentText>
                        <DialogContentText>
                            1. The redemption code is incorrect
                        </DialogContentText>
                        <DialogContentText>
                            2. The redemption is already redeemed or expired
                        </DialogContentText>
                        <DialogContentText>
                            3. Other server errors
                        </DialogContentText>
                        <DialogContentText>
                            Please try again later.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setRedemptionFailDialogOpen(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={redemptionFailCustomDialogOpen}
                    onClose={() => setRedemptionFailCustomDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Something went wrong :(`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {redemptionFailCustomMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setRedemptionFailCustomDialogOpen(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </ThemeProvider >
    );
}
