import * as React from 'react';
import useStyles from './styles';
import Box from '@mui/material/Box';
import amuroLogo from '../images/Amuro_logo_wip_banner_cropped.png';

const NavigationBar = () => {
    const themedStyles = useStyles();

    return (
        <div className={themedStyles.container}>
            <img src={amuroLogo} alt="Logo" className={themedStyles.titleImage} />
        </div>
    );
}

export default NavigationBar;
