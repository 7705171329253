import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        position: 'relative',
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
        width: '100% !important',
        padding: '0 1.5rem 1rem 1.5rem !important',
        marginBottom: '1rem !important',
        maxWidth: 'none !important',
    },
    mainContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingTop: '4rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    developingTitleText: {
        fontSize: '1rem !important',
        fontFamily: 'Montserrat !important',
        fontWeight: '400 !important',
        letterSpacing: '0.15px',
        lineHeight: '140% !important',
        color: theme.palette.text.mainBlue,
        background: theme.palette.background.lightBlue,
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '53rem',
        width: '100%',
        textAlign: 'center',
        padding: '0.7rem 1.5rem 0.7rem 1.5rem'
    },
    titleImage: {
        display: "block",
        maxWidth: '100%',
        width: '30rem',
        textColor: theme.palette.text.lightBlue,
        padding: '3.8rem 1.5rem 2.5rem 1.5rem',
    },
    titleText: {
        fontFamily: 'Montserrat !important',
        fontWeight: '500 !important',
        fontSize: '1rem !important',
        letterSpacing: '0.15px !important',
        lineHeight: '140% !important',
        color: '#193361'
    },
    metaMaskButton: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        maxWidth: '100%',
        width: '30rem',
        height: '2.6rem !important',
        borderRadius: '0.3rem !important',
        lineHeight: 'auto !important',
        display: 'flex !important',
        flexDirection: 'row',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        margin: '0.75rem 0 1rem 0 !important',
    },
    metaMaskIcon: {
    },
    metaMaskText: {
        fontFamily: 'Montserrat !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        letterSpacing: '0.5px !important',
        textTransform: 'capitalize',
        color: `${theme.palette.text.white} !important`,
    },
    contentBoxContainer: {
        display: 'flex !important',
        flexDirection: 'column',
        alignSelf: 'center',
        maxWidth: '53rem',
        width: '100%',
        padding: '2rem 1.2rem 2rem 1.2rem',
        backgroundColor: theme.palette.background.white,
        borderRadius: '0.75rem',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
        zIndex: 1,
    },
    metaMaskAdditionInfoContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        maxWidth: '100%',
    },
    metaMaskMemoText: {
        fontFamily: 'Montserrat !important',
        fontSize: '0.75rem !important',
        fontWeight: '400 !important',
        letterSpacing: '0.4px !important',
        textTransform: 'none',
        color: `${theme.palette.text.mainBlue} !important`,
        whiteSpace: 'pre-line',
        textAlign: 'left',
    },
    signInSeperatorContainer: {
        display: 'flex',
        maxWidth: '100%',
        width: '30rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1.25rem 0 1.25rem 0',
    },
    seperatorStroke: {
        display: 'flex',
        flex: 1,
        height: 0,
        border: `1px solid #A8A8A8`
    },
    qrCodeScannerContainer: {
        display: 'flex',
        flex: 1,
        maxWidth: '90%',
        width: '20rem',
        marginBottom: '0.5rem'
    },
    closeScannerButton: {
        border: `1px solid ${theme.palette.text.lightGrey} !important`,
        borderRadius: '6.25rem !important',
        display: 'flex',
        maxWidth: '100%',
        width: '10rem',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1.5rem !important'
    },
    closeScannerButtonText: {
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.8rem !important',
        fontWeight: '400 !important',
        letterSpacing: '0.4px !important',
        lineHeight: '1.25rem !important',
        textTransform: 'none !important',
        color: `${theme.palette.text.lightBlue} !important`,
        cursor: 'pointer'
    },
    signInSeperatorText: {
        fontFamily: 'Noto Sans TC !important',
        fontWeight: '400 !important',
        fontSize: '0.87rem !important',
        lineHeight: '1.25rem !important',
        color: `${theme.palette.text.grey} !important`,
        padding: '0 1rem 0 1rem',
    },
    signinHelperContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '0.5rem',
    },
    learnMoreText: {
        fontFamily: 'Montserrat !important',
        fontWeight: '700 !important',
        fontSize: '0.75rem !important',
        letterSpacing: '1.5px !important',
        textDecorationLine: 'underline',
        textTransform: 'uppercase',
        textAlign: 'left',
        color: `${theme.palette.text.mainBlue} !important`,
    },
    fetchingCustodianWalletText: {
        fontFamily: 'Montserrat !important',
        fontWeight: '500 !important',
        fontSize: '0.8rem !important',
        letterSpacing: '0.15px !important',
        lineHeight: '140% !important',
        color: '#193361'
    },
    signInButtonContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    submitContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    userConnectedStatusText: {
        fontFamily: 'Montserrat !important',
        fontSize: '0.8rem !important',
        fontWeight: '400 !important',
        letterSpacing: '0.4px !important',
        color: `${theme.palette.text.mainBlue} !important`,
        textAlign: 'center',
        lineHeight: '140%',
        whiteSpace: 'pre-line',
    },
    walletTooltipWrapper: {
        display: 'flex',
        flex: 1,
    },
    walletStatusContainer: {
        border: `1px solid ${theme.palette.text.lightGrey}`,
        borderRadius: '6.25rem',
        display: 'flex',
        maxWidth: '100%',
        width: '25.5rem',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '1rem',
        padding: '0.5rem 1.25rem',
        overflow: 'hidden !important',
    },
    walletStatusSeperator: {
        border: `1px solid ${theme.palette.text.lightGrey}`,
        transform: 'rotate(90deg)',
        display: 'flex',
        flex: 'none',
        padding: '0 0.625rem',
    },
    walletStatusTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: "center",
        flex: 1,
        overflow: 'hidden !important',
        margin: '0 2rem !important',
        [theme.breakpoints.down('md')]: {
            margin: '0 1rem !important',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 !important',
        },
    },
    walletStatusText: {
        // whiteSpace: 'nowrap !important',
        // overflow: 'hidden !important',
        // textOverflow: 'ellipsis !important',
        textAlign: 'center',
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.8rem !important',
        fontWeight: '400 !important',
        letterSpacing: '0.4px !important',
        lineHeight: '1.25rem !important',
        margin: '0 0 0 0 !important',
        color: `${theme.palette.text.grey} !important`,
    },
    walletSuffixText: {
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.8rem !important',
        fontWeight: '400 !important',
        letterSpacing: '0.4px !important',
        lineHeight: '1.25rem !important',
        color: `${theme.palette.text.lightGrey} !important`,
    },
    disconnectText: {
        display: 'flex',
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.8rem !important',
        fontWeight: '400 !important',
        letterSpacing: '0.4px !important',
        lineHeight: '1.25rem !important',
        color: `${theme.palette.text.lightBlue} !important`,
        cursor: 'pointer'
    },
    submitSeperatorContainer: {
        display: 'flex',
        maxWidth: '100%',
        width: '25.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1.2rem 0 1.2rem 0',
    },
    submitSeperatorText: {
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.87rem !important',
        fontWeight: '400 !important',
        lineHeight: '1.25rem !important',
        textAlign: 'center',
        whiteSpace: 'pre-line',
        color: `${theme.palette.text.grey} !important`,
        padding: '0 1rem 0 1rem',
    },
    redeemCodeInput: {
        maxWidth: '100%',
        width: '25.5rem',
    },
    submitButton: {
        backgroundColor: `${theme.palette.primary.main}`,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: "center",
        maxWidth: '100%',
        width: '25.5rem',
        padding: '0.75rem 1rem 0.75rem 1rem !important',
        margin: '1rem 0 1rem 0 !important',
    },
    submitButtonText: {
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.8rem !important',
        fontWeight: '400 !important',
        lineHeight: '1.25rem !important',
        color: `${theme.palette.text.white} !important`,
    },
}));

export default useStyles;
