import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { isMobile } from 'react-device-detect';
import firebase from "firebase/compat/app";
import { signInWithGoogle, signOut } from '../../services/firebase';

// Components
import CreateWallet from './CreateWallet';
import RetrieveWallet from './RetrieveWallet';
import FirebaseSignIn from './FirebaseSignIn';

// Controllers
import { findWallet, isWalletExist } from '../../controllers/amuro_services';

// Utils
import { requireValues } from './input_utils';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://hellopark.app">
                Inertia Company Limited
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const PHASE_UNKNOWN = -1;
const PHASE_FIREBASE_LOGGED_OUT = 0;
const PHASE_FIREBASE_LOGGED_IN = 1;
const PHASE_PRE_RETRIEVE_WALLET = 2;
const PHASE_RETRIEVED_WALLET = 3;
const PHASE_CREATE_WALLET = 4;
const PHASE_MAX = 100;

export default function SignIn() {
    const [firebaseUser, setFirebaseUser] = React.useState(null);
    const [showInstallMetamaskMessage, setShowInstallMetamaskMessage] = React.useState(false);
    const [walletInfo, setWalletInfo] = React.useState(null);
    const [status, setStatus] = React.useState(PHASE_FIREBASE_LOGGED_OUT);

    const onFirebaseAuthButtonClick = (event) => {
        event.preventDefault();
        signInWithGoogle();
    }

    const onFirebaseSignoutButtonClick = async (event) => {
        event.preventDefault();
        await signOut();
        setStatus(PHASE_FIREBASE_LOGGED_OUT);
    }

    const onRetrieveWalletInfoButtonClick = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (firebaseUser) {
            let firebaseJwtToken = await firebaseUser.getIdToken();
            console.log(`firebaseJwtToken: ${firebaseJwtToken}`);
            let password = data.get('password');
            console.log(`password: ${password}`);
            let wallet = await findWallet(firebaseJwtToken, password);
            setWalletInfo(wallet);
            setStatus(PHASE_RETRIEVED_WALLET);
        }
    }

    const onConnectMetaMaskButtonClick = async (event) => {
        event.preventDefault();
    }

    const onCreateWalletButtonClick = async (event) => {
        event.preventDefault();
    }

    const requireStatus = (minStatus, maxStatus = minStatus) => {
        return (minStatus <= status) && (status <= maxStatus);
    }

    const getInstallMetaMaskComponent = () => {
        if (isMobile) {
            return <Typography>
                {`Please `}
                <Link onClick={() => { openLink(getMetamaskInstallUrl(isMobile)) }} variant="body1">install MetaMask</Link>
                {` to proceed if you have not.`}
            </Typography>
        } else {
            return <Typography>
                {`MetaMask is not detected, you need to `}
                <Link onClick={() => { openLink(getMetamaskInstallUrl(isMobile)) }} variant="body1">install MetaMask</Link>
                {` to proceed`}
            </Typography>
        }
    }

    const getConnectMetaMaskButton = () => {
        return <Button
            type="submit"
            onClick={onConnectMetaMaskButtonClick}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
        >
            connect MetaMask
        </Button>
    }

    const getUserProfileDisplayText = (user) => {
        let elements = [];
        if (user.email) {
            elements.push(user.email);
        }
        if (user.phoneNumber) {
            elements.push(user.phoneNumber);
        }
        if (elements.length > 0) {
            return `${user.displayName} (${elements.join(', ')})`;
        } else {
            return user.displayName;
        }
    }

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            setFirebaseUser(firebaseUser);
            if (firebaseUser) {
                let exist = await isWalletExist(await firebaseUser.getIdToken());
                if (exist) {
                    setStatus(PHASE_PRE_RETRIEVE_WALLET);
                } else {
                    setStatus(PHASE_CREATE_WALLET);
                }
            }
        })
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="m">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Box component="form" onSubmit={onRetrieveWalletInfoButtonClick} noValidate sx={{ mt: 1 }}>

                        {
                            <Typography>
                                {firebaseUser ? `Signed in as ${<Typography variant='h1'>getUserProfileDisplayText(firebaseUser)</Typography>}` : `Please sign-in to continue`}
                                {`  `}
                                {firebaseUser && <Link onClick={onFirebaseSignoutButtonClick} variant="body1">sign out</Link>}
                            </Typography>
                        }
                        {
                            requireStatus(PHASE_UNKNOWN, PHASE_FIREBASE_LOGGED_OUT) &&
                            requireValues([firebaseUser]) &&
                            <FirebaseSignIn />
                        }
                        {
                            requireStatus(PHASE_PRE_RETRIEVE_WALLET) && <RetrieveWallet firebaseUser={firebaseUser} />
                        }
                        {
                            requireStatus(PHASE_FIREBASE_LOGGED_IN) &&
                            requireValues([firebaseUser]) &&
                            <Container>
                                <Typography>
                                    Enter one of your passwords to retrieve your wallet info:
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password to retrieve wallet"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password" />
                            </Container>
                        }
                        {
                            requireStatus(PHASE_FIREBASE_LOGGED_IN) &&
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}>
                                Retrieve wallet info
                            </Button>
                        }
                        {requireStatus(PHASE_RETRIEVED_WALLET) &&
                            isMobile && walletInfo && getInstallMetaMaskComponent() && getConnectMetaMaskButton()}
                        {(requireStatus(PHASE_RETRIEVED_WALLET) &&
                            !isMobile && walletInfo) ?
                            checkIfMetamaskInstalled() ? getConnectMetaMaskButton() : getInstallMetaMaskComponent()
                            :
                            null
                        }
                    </Box>
                    {
                        (requireStatus(PHASE_CREATE_WALLET)) &&
                        <CreateWallet />
                    }
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
