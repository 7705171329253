import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#C22A37',
        },
        secondary: {
            main: '#3266C2',
        },
        background: {
            default: "#f4f4f4",
            white: '#FFF',
            orange: '#FFA500',
            lightBlue: 'rgba(50, 102, 194, 0.08)',
            darkGreen: '#006055',
            lightGreen: 'rgba(0, 96, 85, 0.1)',
            defaultGreen: 'rgba(51, 125, 120, 0.1)',
        },
        error: {
            main: red.A400,
        },
        text: {
            mainBlue: '#214482',
            mainRed: '#B20000',
            white: '#FFF',
            grey: '#808080',
            lightGrey: '#c7c7c7',
            lightBlue: '#3266C2',
        },
        general: {
            white: '#FFF',
        },
        cathay: {
            main: '#006055',
        }
    },
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 12,
        h4: {
            fontSize: 80,
        }
    },
});

export default theme;