import { ethers } from "ethers";
import detectEthereumProvider from '@metamask/detect-provider'
import { isMobile } from 'react-device-detect';

// Project
import { getSigningMessage } from "../controllers/amuro_services";

// Utils
import * as StringUtils from '../utils/string_utils';

export const METAMASK_STATUS_UNKNOWN = 'unknown';
export const METAMASK_STATUS_INSTALLED_WEB = 'installed_web';
export const METAMASK_STATUS_INSTALLED_MOBILE = 'installed_mobile';
export const METAMASK_STATUS_ASK_TO_INSTALL_METAMASK_WEB_EXTENSION = 'ask_to_install_metamask_extension';
export const METAMASK_STATUS_REDIRECT_TO_METAMASK_APP = 'redirect_to_metamask_app';

export function getMetamaskInstallUrl(isMobile) {
    if (isMobile) {
        return `https://metamask.app.link/skAH3BaF99`;
    } else {
        // Web
        return `https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn`;
    }
}

export async function switchToChainIfNecessary(ethereum, chainId, networkName, rpcUrls, nativeCurrency) {
    let chainIdHex = `0x${chainId.toString(16)}`;
    try {
        await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainIdHex }],
        });
        return true;
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            try {
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: chainIdHex,
                            chainName: networkName,
                            rpcUrls: rpcUrls,
                            nativeCurrency: nativeCurrency
                        },
                    ],
                });
                return true;
            } catch (addError) {
                console.log(addError);
            }
        } else {
            console.log(`Unexpected error:`);
            console.log(switchError);
        }
    }
    return false;
}

export async function requestSignMessage(ethereum) {
    if (ethereum) {
        const messageResult = await getSigningMessage();
        if (messageResult && messageResult.message) {
            const message = messageResult.message;
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            const signature = await signer.signMessage(message);
            let body = {
                message: message,
                signature: signature,
                address: address
            };
            let encoded = StringUtils.encodeBase64(JSON.stringify(body));
            return encoded;
        } else {
            console.log(`Failed to get signing message from server`);
        }
    } else {
        console.log(`No window.ethereum found`);
    }
    return null;
}

export async function requestConnectMetamask(ethereum, networkInfo) {
    try {
        await switchToChainIfNecessary(ethereum, networkInfo.chain_id, networkInfo.network_name,
            networkInfo.urls,
            {
                name: networkInfo.currency_name,
                symbol: networkInfo.symbol,
                decimals: networkInfo.decimals
            });
        await ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await ethereum.request({ method: 'eth_accounts' });
        if (accounts && accounts.length > 0) {
            console.log(`wallet connected: ${accounts[0]}`);
            return accounts[0];
        } else {
            console.log(`Metamask returns no account`);
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getMetamaskInstallStatus(window) {
    const provider = await detectEthereumProvider();
    let isMetamaskInstalled = false;
    if (provider) {
        console.log('Ethereum successfully detected!')
        // From now on, this should always be true:
        // provider === window.ethereum

        // Access the decentralized web!

        // Legacy providers may only have ethereum.sendAsync
        const chainId = await provider.request({
            method: 'eth_chainId'
        })
        const { ethereum } = window;
        if (ethereum && ethereum.isMetaMask) {
            isMetamaskInstalled = true;
            return isMobile ? METAMASK_STATUS_INSTALLED_MOBILE:  METAMASK_STATUS_INSTALLED_WEB;
        }
    }
    if (!isMetamaskInstalled) {
        if (isMobile) {
            return METAMASK_STATUS_REDIRECT_TO_METAMASK_APP;
        } else {
            return METAMASK_STATUS_ASK_TO_INSTALL_METAMASK_WEB_EXTENSION;
        }
    }
}
