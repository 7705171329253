

import * as React from 'react';

// MUI
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles'
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import theme from '../../theme';

export default function AdminCheatSheet() {
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        // alignItems: 'center',
                    }}
                >
                    <Link color="inherit" href="/developer">Developer API key</Link>
                    <Link color="inherit" href="/claim-wallet">Transfer-out wallet</Link>
                    <Link color="inherit" href="/redeem-cx">CX demo</Link>
                    <Link color="inherit" href="/member">Loyalty program demo</Link>
                    <Link color="inherit" href="/campaign-summary">Campaign redemption summary</Link>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
