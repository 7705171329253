import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        position: 'relative',
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
        width: '100% !important',
        padding: '0 1.5rem 1rem 1.5rem !important',
        marginBottom: '1rem !important',
        maxWidth: 'none !important',
    },
    mainContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingTop: '4rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    amuroTokenTitleText: {
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.87rem !important',
        fontWeight: '400 !important',
        lineHeight: '1.25rem !important',
        textAlign: 'center',
        whiteSpace: 'pre-line',
        color: `${theme.palette.text.grey} !important`,
        padding: '0 1rem 0 1rem',
    },
    downloadButton: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    showTokenButton: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: "center",
        maxWidth: '100%',
        width: '25.5rem',
        padding: '0.75rem 1rem 0.75rem 1rem !important',
        margin: '1rem 0 1rem 0 !important',
    },
    showTokenButtonText: {
        fontFamily: 'Noto Sans TC !important',
        fontSize: '0.8rem !important',
        fontWeight: '400 !important',
        lineHeight: '1.25rem !important',
        color: `${theme.palette.text.white} !important`,
    },
}));

export default useStyles;
