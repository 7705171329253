export function explorerUrl(chainId) {
    let url;
    switch (chainId) {
        case 80001:
            url = `https://mumbai.polygonscan.com`;
            break;
        case 137:
            url = `https://www.polygonscan.com`;
            break;
        default:
            console.log(`Unknown chainId: ${chainId}`);
            break;
    }
    return url;
}

export function isMetamaskInstalled() {
    const { ethereum } = window;
    let result = Boolean(ethereum && ethereum.isMetaMask);
    if (result) {
        console.log('MetaMask is installed!');
    } else {
        console.log(`MetaMask is not installed`);
    }
    return result;
}

export function shouldShowMetamaskInstallGuide(isMobile) {
    if (isMobile) {
        return true;
    } else {
        return (!isMetamaskInstalled());
    }
}

export async function assertCurrentNetwork(ethereum, chainId) {
    let currentChain = await ethereum.request({ method: 'eth_chainId' });
    let currentChainDec = parseInt(currentChain, 16);
    return (currentChainDec == chainId);
}

export async function getCurrentChainId(ethereum) {
    let currentChain = await ethereum.request({ method: 'eth_chainId' });
    let currentChainDec = parseInt(currentChain, 16);
    return currentChainDec;
}

export function getNetworkInfo() {
    let networkName;
    let chainId;
    let currencyName;
    let symbol;
    let urls;
    let decimals;
    switch (process.env.REACT_APP_ENV) {
        case 'local':
        case 'dev':
            chainId = 80001;
            networkName = 'Mumbai';
            currencyName = 'MATIC';
            symbol = 'MATIC';
            decimals = 18;
            urls = ['https://matic-testnet-archive-rpc.bwarelabs.com', 'https://matic-mumbai.chainstacklabs.com', 'https://rpc-mumbai.maticvigil.com'];
            break;
        case 'prod':
            chainId = 137;
            networkName = 'Polygon Mainnet';
            currencyName = 'MATIC';
            symbol = 'MATIC';
            decimals = 18;
            urls = ['https://polygon-rpc.com/', 'https://matic-mainnet.chainstacklabs.com'];
            break;
        default:
            console.log(`Unknown env: ${process.env.REACT_APP_ENV}`);
            break;
    }
    return {
        network_name: networkName,
        chain_id: chainId,
        currency_name: currencyName,
        symbol: symbol,
        decimals: decimals,
        urls: urls
    }
}

export function isSameAddress(addr1, addr2) {
    if (!addr1 || !addr2) {
        return false;
    }
    return addr1.toLowerCase() == addr2.toLowerCase();
}
