import * as React from 'react';

import { UserProvider } from '../../context/UserProfile';

import RedeemCX from './RedeemCX';
import RedeemBackground from './RedeemBackground';
import NavigationBar from '../nav_bar';
import CXCopyrightFooter from './copyright_footer';

const RedeemMainCX = () => {
    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            background: '#fff',
        }}>
            <UserProvider>
                <NavigationBar />
                <RedeemCX />
                <CXCopyrightFooter />
            </UserProvider >
        </div>
    )
}

export default RedeemMainCX;
