export function encodeBase64(raw) {
    let buff = Buffer.from(raw);
    let base64data = buff.toString('base64');
    return base64data;
}

export function decodeBase64(raw) {
    let buff = Buffer.from(raw, 'base64');
    let text = buff.toString('ascii');
    return text;
}
