import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        position: 'fixed',
        top: 0,
        padding: '1rem 0',
        width: '100%',
        padding: '22px 44px',
        backgroundColor: theme.palette.background.white,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
        zIndex: 10,
        flex: 1,
    },
    titleImage: {
        display: "block",
        maxWidth: '100%',
        width: '8rem',
        textColor: theme.palette.text.lightBlue,
    },
}));

export default useStyles;
