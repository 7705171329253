import * as React from 'react';

import { useMediaQuery, useTheme, } from '@mui/material';

import useStyles from './styles';
import upwardVector from '../../images/upward_vector.svg';
import mobileUpwardVector from '../../images/mobile_upward_vector.svg';
import downwardVector from '../../images/downward_vector.svg';
import mobileDownwardVector from '../../images/mobile_downward_vector.svg';

const RedeemBackground = () => {
    const themedStyles = useStyles();
    const theme = useTheme();
    const isMobileForUI = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <div className={themedStyles.redEllipse} />
            <div className={themedStyles.blueEllipse} />
            <img src={upwardVector} alt="upward_vector" className={themedStyles.upwardVector} />
            {
                (isMobileForUI) ?
                    <img src={mobileDownwardVector} alt="downward_vector" className={themedStyles.downwardVector} /> :
                    <img src={downwardVector} alt="downward_vector" className={themedStyles.downwardVector} />
            }

        </>
    );
}

export default RedeemBackground;
